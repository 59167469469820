/* eslint-disable react/forbid-prop-types */
// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import DeleteIcon from '@mui/icons-material/Delete';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import ActionsListItemButton from '../common/ActionsListItemButton';
import Alert from '../Alert';
import axios from '../AxiosInstance';
import ContentList from '../format-nested-content/ContentList';
import errorCodes from '../utils/error-codes';
import useFolderContents from '../hooks/useFolderContents';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Contexts
// ───────────────────────────────────────────────────────────────────────────
import AlertMessageContext from '../../contexts/AlertMessageContext';
import FolderDataContext from '../../contexts/FolderDataContext';
import UserContext from '../../contexts/UserContext';

const MAGIC_DELETE_WORD = 'DELETE';

function Delete({ folderData, children }) {
  const [alertMessage, setAlertMessage] = useState('');
  const [confirmInput, setConfirmInput] = useState('');
  const [open, setOpen] = useState(false);
  const { user } = useContext(UserContext);
  const { setSuccessMessage } = useContext(AlertMessageContext);
  const { navigateOrRefresh, setSelectedItems } = useContext(FolderDataContext);
  const folderDataArray = useMemo(
    () => (Array.isArray(folderData) ? folderData : [folderData]),
    [folderData]
  );
  const folderIds = useMemo(
    () => folderDataArray.filter((data) => data?.type === 'folder').map((folder) => folder.id),
    [folderDataArray]
  );
  const { contents, isLoading } = useFolderContents(folderIds);
  const hasPermission = useMemo(() => user.isAdmin, [user]);
  // action is disabled if  user does not have permission, not root archived item, or the provided folder is root
  const isDeleteDisabled = useMemo(
    () =>
      !hasPermission ||
      folderDataArray.some((item) => !item.archived || item.archivedBy || item.id === 1),
    [folderDataArray, hasPermission]
  );
  const tooltipTitle = useMemo(() => {
    if (!hasPermission) return 'User does not have permission to use this action';
    if (isDeleteDisabled) return 'Items must be archived before deleting';
    return 'Delete item(s)';
  }, [hasPermission, isDeleteDisabled]);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleChange = useCallback((event) => setConfirmInput(event.target.value), []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setAlertMessage('');
  }, []);

  // on submit, delete the selected folders, then close the dialog and refresh the view
  const onSubmit = useCallback(async () => {
    try {
      await Promise.all(
        folderDataArray.map(async ({ type, id }) => axios.delete(`/api/${type}s/${id}`))
      );

      setSuccessMessage('Successfully deleted item(s)');
      handleClose();
      // all items should have the same parentId, so we can just use the first item
      setSelectedItems([]);
      navigateOrRefresh(folderDataArray[0].parentId);
    } catch (error) {
      // if the user cancelled the confirmation, no error occurred, ignore
      if (error) {
        // on failure alert the user
        if (error?.response) {
          setAlertMessage(
            errorCodes.ERROR_DELETE_API_ERROR.toString(
              error?.response?.data?.message || error.response?.data?.error
            )
          );
        } else {
          setAlertMessage(errorCodes.ERROR_DELETE_INTERNAL.toString(error.message));
        }
      }
    }
  }, [folderDataArray, handleClose, navigateOrRefresh, setSelectedItems, setSuccessMessage]);

  return (
    <>
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      {/* ─── Display Action Button                                                   */}
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      <Tooltip title={tooltipTitle}>
        <span>
          <ActionsListItemButton onClick={handleOpen} disabled={isDeleteDisabled}>
            {children}
          </ActionsListItemButton>
        </span>
      </Tooltip>

      {/* ─────────────────────────────────────────────────────────────────────────── */}
      {/* ─── Display Dialog                                                          */}
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <Alert
          message={alertMessage}
          setMessage={setAlertMessage}
          severity="error"
          variant="alert"
          allowClose
        />
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <DeleteIcon color="secondary" />
          &nbsp;Delete {folderDataArray.length} item(s)
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Confirming this will delete</DialogContentText>
          {isLoading ? <CircularProgress /> : <ContentList contents={contents} />}

          {/* ─────────────────────────────────────────────────────────────────────────── */}
          {/* ─── Confirm Delete TextBox                                                  */}
          {/* ─────────────────────────────────────────────────────────────────────────── */}
          <br />
          <Typography
            variant="body1"
            style={{ whiteSpace: 'pre-line' }}
            data-testid="confirmation-text"
          >
            Are you sure you want to delete this folder and all folders and documents inside ot it?
            {'\n\n'}
            If you want to, type in &quot;{MAGIC_DELETE_WORD}&quot; and press the button.
          </Typography>
          <br />
          <TextField
            fullWidth
            label={MAGIC_DELETE_WORD}
            variant="outlined"
            onChange={handleChange}
            inputProps={{ 'data-testid': 'delete-selected-text-field' }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            onClick={onSubmit}
            disabled={confirmInput !== MAGIC_DELETE_WORD}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Delete;

Delete.propTypes = {
  folderData: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        parentId: PropTypes.number,
        title: PropTypes.string,
        type: PropTypes.string,
      })
    ),
    PropTypes.object,
  ]).isRequired,
  children: PropTypes.node.isRequired,
};
