// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import Alert from '../components/Alert';

const AlertMessageContext = createContext();

/**
 * A component that wraps the success message context provider.
 */
export function AlertMessageProvider({
  successMessage,
  setSuccessMessage,
  errorMessage,
  setErrorMessage,
  children,
}) {
  const memoizedValue = useMemo(
    () => ({
      successMessage,
      setSuccessMessage,
      errorMessage,
      setErrorMessage,
    }),
    [successMessage, setSuccessMessage, errorMessage, setErrorMessage]
  );

  return (
    <AlertMessageContext.Provider value={memoizedValue}>
      {children}
      <Alert
        message={successMessage}
        setMessage={setSuccessMessage}
        severity="success"
        variant="snackbar"
      />
      <Alert
        message={errorMessage}
        setMessage={setErrorMessage}
        severity="error"
        variant="snackbar"
      />
    </AlertMessageContext.Provider>
  );
}

export default AlertMessageContext;

AlertMessageProvider.propTypes = {
  successMessage: PropTypes.string,
  setSuccessMessage: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  setErrorMessage: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
