import PropTypes from 'prop-types';

import {
  GoToSpecificDocumentWidget,
  GoToSpecificDocumentWidgetPreview,
  ID as GoToSpecificDocumentID,
  Options as GoToSpecificDocumentOptions,
} from '../widgets/GoToSpecificDocumentWidget';
import {
  ID as NextDocumentNumberID,
  NextDocumentNumberWidget,
  NextDocumentNumberWidgetPreview,
  Options as NextDocumentNumberOptions,
} from '../widgets/NextDocumentNumberWidget';

/**
 * Holds which widgets are registered in the system
 * Stored in the form of
 * ```js
 *   {
 *     [WidgetId]: {
 *        widget: WidgetInstance,
 *        widgetPreview: WidgetPreviewInstance,
 *        title: 'Title of the widget',
 *     }
 *   }
 * ```
 *
 * Where instance is just the import and id is a unique string
 */
const RegisteredWidgets = {
  [GoToSpecificDocumentID]: {
    widget: GoToSpecificDocumentWidget,
    widgetPreview: GoToSpecificDocumentWidgetPreview,
    title: 'Go To Specific Document ID',
    options: GoToSpecificDocumentOptions,
  },
  [NextDocumentNumberID]: {
    widget: NextDocumentNumberWidget,
    widgetPreview: NextDocumentNumberWidgetPreview,
    title: 'Next Document Number',
    options: NextDocumentNumberOptions,
  },
};

/**
 * Get a registered widget by ID
 *
 * @param {String/number} id widget id
 * @returns WidgetInstance
 */
function RegisteredWidget(id) {
  return RegisteredWidgets[id].widget;
}

/**
 * Get a registered widget preview by ID
 *
 * @param {String/number} id widget id
 * @returns WidgetInstance
 */
function RegisteredWidgetPreview(id) {
  return RegisteredWidgets[id].widgetPreview;
}

export { RegisteredWidget, RegisteredWidgetPreview, RegisteredWidgets };

RegisteredWidget.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

RegisteredWidgetPreview.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
