import { useAuth0 } from '@auth0/auth0-react';

/**
 * Logout useHook that returns a function to logout the user
 *
 * @returns {Function} logout.logoutHook - logout function
 */
const useLogout = () => {
  const { logout } = useAuth0();

  return () => logout({ returnTo: window.location.origin });
};

export default useLogout;
