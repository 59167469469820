import React from 'react';

import Paper from '@mui/material/Paper';

import WidgetDashboard from '../components/widget-framework/WidgetDashboard';

function Home() {
  return (
    <Paper elevation={0} style={{ borderRadius: '1.25rem', height: '100%' }}>
      <h1 style={{ marginTop: 15, paddingLeft: 15 }} data-testid="home">
        Home | Dashboard
      </h1>
      <WidgetDashboard />
    </Paper>
  );
}

export default Home;
