import React, { useState } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import ResetPreferences from '../components/ResetPreferences';
import WidgetSettings from '../components/widget-framework/WidgetSettings';

import Alert from '../components/Alert';

function Preferences() {
  const [message, setMessage] = useState('');
  return (
    <center>
      <Card>
        <CardContent>
          <WidgetSettings />
          <ResetPreferences setMessage={setMessage} />
        </CardContent>
      </Card>
      <Alert
        message={message}
        setMessage={setMessage}
        severity="success"
        variant="snackbar"
        title=""
      />
    </center>
  );
}

export default Preferences;
