/**
 * Used to return error messages
 * and default to a specific issue when none is supplied
 *
 * @param {String} errorMessage - The error message to return
 * @returns {String} The error message
 */
function toString(errorMessage) {
  return `${errorMessage || this.message} (Code: ${this.code})`;
}
const SERVER_ERROR_MESSAGE = 'Sorry, our server encountered an error ';
const INTERNAL_ERROR_MESSAGE = 'Sorry, an internal error occurred';

const errorCodes = {
  ERROR_CREATE_FOLDER_API_ERROR: {
    message: `${SERVER_ERROR_MESSAGE} when creating this folder.`,
    code: 33,
    toString,
  },
  ERROR_CREATE_FOLDER_INTERNAL: {
    message: `${INTERNAL_ERROR_MESSAGE} creating this folder.`,
    code: 49,
    toString,
  },
  ERROR_ARCHIVE_API: {
    message: `${SERVER_ERROR_MESSAGE} when archiving this folder.`,
    code: 34,
    toString,
  },
  ERROR_ARCHIVE_INTERNAL: {
    message: `${INTERNAL_ERROR_MESSAGE} when archiving this folder.`,
    code: 50,
    toString,
  },
  ERROR_DELETE_API_ERROR: {
    message: `${SERVER_ERROR_MESSAGE} when deleting this folder.`,
    code: 35,
    toString,
  },
  ERROR_DELETE_INTERNAL: {
    message: `${INTERNAL_ERROR_MESSAGE} when deleting this folder.`,
    code: 51,
    toString,
  },
  ERROR_EDIT_FOLDER_API: {
    message: `${SERVER_ERROR_MESSAGE} editing this folder.`,
    code: 36,
    toString,
  },
  ERROR_EDIT_FOLDER_INTERNAL: {
    message: `${INTERNAL_ERROR_MESSAGE} editing this folder.`,
    code: 52,
    toString,
  },
  ERROR_UPLOAD_DOCUMENT_API: {
    message: `${SERVER_ERROR_MESSAGE} uploading this document.`,
    code: 37,
    toString,
  },
  ERROR_UPLOAD_DOCUMENT_INTERNAL: {
    message: `${INTERNAL_ERROR_MESSAGE} uploading this document.`,
    code: 53,
    toString,
  },
  ERROR_MOVE_API: {
    message: `${SERVER_ERROR_MESSAGE} when moving this folder.`,
    code: 38,
    toString,
  },
  ERROR_MOVE_INTERNAL: {
    message: `${INTERNAL_ERROR_MESSAGE} moving this folder.`,
    code: 54,
    toString,
  },
  ERROR_MOVE_API_VALIDATE: {
    message: `An error occurred when validating this folder.`,
    code: 39,
    toString,
  },
  ERROR_MOVE_API_FAILURE: {
    message: `${INTERNAL_ERROR_MESSAGE} moving this folder.`,
    code: 40,
    toString,
  },
};

export default errorCodes;
