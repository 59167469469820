/* eslint-disable react/forbid-prop-types */
// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import Content from './Content';

function ContentList({ contents }) {
  return (
    <List component="div" disablePadding>
      {contents
        // remove all but the parents as the `Content` component iterate over all children
        .map((parent) => {
          // TODO: IAD-200 display documents to delete <Content key={parent.id} contents={parent.contents.documents} item={parent} />
          return <Content key={parent.id} item={parent} contents={parent.contents.folders} />;
        })}
    </List>
  );
}

export default ContentList;

ContentList.propTypes = {
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      contents: PropTypes.shape({
        folders: PropTypes.array,
        documents: PropTypes.array,
      }),
    })
  ),
};
