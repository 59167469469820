/* eslint-disable */
const DefaultGridItemOptions = {
  // These are all in grid units, not pixels
  x: 0,
  y: 0,
  w: 1,
  h: 1,
  minW: 0,
  maxW: Infinity,
  minH: 0,
  maxH: Infinity,

  // If true, equal to `isDraggable: false, isResizable: false`.
  static: false,
  // If false, will not be draggable. Overrides `static`.
  isDraggable: true,
  // If false, will not be resizable. Overrides `static`.
  isResizable: true,
  // By default, a handle is only shown on the bottom-right (southeast) corner.
  // Note that resizing from the top or left is generally not intuitive.
  resizeHandles: ['se'],
  // If true and draggable, item will be moved only within grid.
  isBounded: false,
};

const ApplyGridItemDefaults = (options) => {
  const newOptions = { ...DefaultGridItemOptions, ...options };
  // if it's null, use the default value
  for (const key in newOptions) {
    if (newOptions[key] === null) {
      newOptions[key] = DefaultGridItemOptions[key];
    }
  }

  return newOptions;
};

export { ApplyGridItemDefaults };
