// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useConfirm } from 'material-ui-confirm';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import ActionsListItemButton from '../common/ActionsListItemButton';
import Alert from '../Alert';
import axios from '../AxiosInstance';
import errorCodes from '../utils/error-codes';
import FormStringInput from '../FormStringInput';
import PermissionOptions from './PermissionOptions';
import PermissionsTable from './PermissionsTable';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Contexts
// ───────────────────────────────────────────────────────────────────────────
import AlertMessageContext from '../../contexts/AlertMessageContext';
import FolderDataContext from '../../contexts/FolderDataContext';
import UserContext from '../../contexts/UserContext';

function EditFolder({ folderData, children }) {
  // ───────────────────────────────────────────────────────────────────────────
  // ─── State Management
  // ───────────────────────────────────────────────────────────────────────────
  const [alertMessage, setAlertMessage] = useState('');
  const [open, setOpen] = useState(false);

  // ───────────────────────────────────────────────────────────────────────────
  // ─── Contexts & Hooks
  // ───────────────────────────────────────────────────────────────────────────
  const { user } = useContext(UserContext);
  const { setSuccessMessage } = useContext(AlertMessageContext);
  const { updateFolderData, loading, folderPermissions } = useContext(FolderDataContext);
  const { control, handleSubmit, reset, formState } = useForm({
    defaultValues: { permissionsOption: folderPermissions.inheritPermissions },
  });
  const confirm = useConfirm();
  const singleFolderData = useMemo(() => folderData[0] ?? folderData, [folderData]);
  const isRootFolder = useMemo(() => singleFolderData.id === 1, [singleFolderData]);
  const hasPermission = useMemo(
    () => user.isAdmin || singleFolderData.permissions?.rename,
    [user, singleFolderData]
  );
  const isDisabled = useMemo(
    () => !hasPermission || (Array.isArray(folderData) && folderData.length > 1),
    [folderData, hasPermission]
  );
  const tooltipTitle = useMemo(() => {
    if (!hasPermission) return 'User does not have permission to use this action';
    if (isDisabled) return 'More than 1 item selected';
    return 'Edit Item';
  }, [hasPermission, isDisabled]);
  // ───────────────────────────────────────────────────────────────────────────
  // ─── Handlers
  // ───────────────────────────────────────────────────────────────────────────
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleChange = useCallback(
    (event) => {
      // permission options value is either `inherits` or `own`
      folderPermissions.setInheritPermissions(event.target.value === 'inherits');
    },
    [folderPermissions]
  );
  const handleReset = useCallback(() => {
    folderPermissions.resetFolderDataPermissions();
    reset();
  }, [folderPermissions, reset]);
  const handleClose = useCallback(async () => {
    if (formState.isDirty) {
      try {
        await confirm({
          title: (
            <>
              <WarningIcon color="secondary" /> Warning
            </>
          ),
          description: `You have unsaved changes.  Are you sure you want to close and lose your changes?`,
          cancellationButtonProps: { 'data-testid': 'confirm-cancel' },
          confirmationButtonProps: { 'data-testid': 'confirm' },
        });
      } catch {
        // if the user cancels the confirm, cancel other actions
        return false;
      }
    }
    // reset the folder permissions along with react-hook-form's `isDirty` tracker
    handleReset();
    setOpen(false);

    return true;
  }, [confirm, handleReset, formState.isDirty]);

  const onSubmit = useCallback(
    async ({ description, name, permissionsOption, permissions }) => {
      // only add description and name if they are different
      const patchQuery = {
        ...(description && { description }),
        ...(name && { name }),
        // if permission direction is `inherits`, send empty object,
        // if permission direction is `own`, send defined permissions
        permissions: permissionsOption === 'inherits' ? {} : permissions,
      };

      try {
        await axios.patch(`/api/folders/${singleFolderData.id}`, patchQuery);

        // if successful, alert user, refresh view, and reset the form
        setSuccessMessage(`Successfully updated ${singleFolderData.name}`);
        updateFolderData();
        folderPermissions.resetFolderDataPermissions();
        reset({}, { keepValues: true });
        setOpen(false);
      } catch (error) {
        // on failure alert the user
        if (error.response) {
          setAlertMessage(
            errorCodes.ERROR_EDIT_FOLDER_API.toString(
              error.response?.data?.message || error.response?.data?.error
            )
          );
        } else {
          setAlertMessage(errorCodes.ERROR_EDIT_FOLDER_INTERNAL.toString(error.message));
        }
      }
    },
    [
      singleFolderData.id,
      singleFolderData.name,
      folderPermissions,
      reset,
      setSuccessMessage,
      updateFolderData,
    ]
  );

  const handleConfirm = useCallback(async () => {
    // only display the confirm if the user has changed permissions
    // if folder is inheriting permissions, confirm they want to specify their own perms
    if (
      Object.keys(formState.dirtyFields).some(
        (field) => field !== 'name' && field !== 'description'
      ) &&
      folderPermissions.folderDataPermissions.isInherited
    ) {
      try {
        await confirm({
          title: (
            <>
              <WarningIcon color="secondary" /> Warning
            </>
          ),
          description:
            `You've modified permissions.  ` +
            `This folder and its children will be converted to specified permissions ` +
            `and will no longer inherit from ${
              folderPermissions.folderDataPermissions.inheritedFrom._id === 1
                ? 'global permissions'
                : folderPermissions.folderDataPermissions.inheritedFrom.name
            }`,
          cancellationButtonProps: { 'data-testid': 'confirm-cancel' },
          confirmationButtonProps: { 'data-testid': 'confirm' },
        });
      } catch {
        return false;
      }
    }
    return handleSubmit(onSubmit)();
  }, [
    formState.dirtyFields,
    folderPermissions.folderDataPermissions.isInherited,
    folderPermissions.folderDataPermissions.inheritedFrom?._id,
    folderPermissions.folderDataPermissions.inheritedFrom?.name,
    handleSubmit,
    onSubmit,
    confirm,
  ]);

  return (
    <>
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      {/* ─── Display Action Button                                                   */}
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      <Tooltip title={tooltipTitle}>
        <span>
          <ActionsListItemButton onClick={handleOpen} disabled={isDisabled}>
            {children}
          </ActionsListItemButton>
        </span>
      </Tooltip>

      {/* ───────────────────────────────────────────────────────────────────────── */}
      {/* ─── Display Dialog                                                        */}
      {/* ───────────────────────────────────────────────────────────────────────── */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <Alert
          message={alertMessage}
          setMessage={setAlertMessage}
          severity="error"
          variant="alert"
          allowClose
        />
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <EditIcon color="secondary" /> &nbsp;Edit Folder: {singleFolderData.name}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={4}>
            {/* Root folder cannot be renamed */}
            {!isRootFolder && (
              <Grid item>
                <Typography variant="h5" gutterBottom>
                  Rename
                  <Divider />
                </Typography>
                <Grid container direction="column" spacing={3}>
                  <Grid item>
                    <FormStringInput
                      control={control}
                      defaultValue={singleFolderData.name}
                      label="Name"
                      name="name"
                      rules={{ required: true }}
                      id="renameFolderInput"
                    />
                  </Grid>
                  <Grid item>
                    <FormStringInput
                      control={control}
                      defaultValue={singleFolderData.description}
                      label="Description"
                      name="description"
                      textFieldOptions={{ multiline: true }}
                      rules={{ required: false }}
                      id="renameFolderDescription"
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            {/* ────────────────────────────────────────────────────────────── */}
            {/* ─── Update Permissions                                         */}
            {/* ────────────────────────────────────────────────────────────── */}
            {user.isAdmin &&
              folderPermissions.folderDataPermissions &&
              (loading ? (
                <center>
                  <br />
                  Loading Permission Data
                  <br />
                  <CircularProgress />
                </center>
              ) : (
                <Grid item data-testid="editPermissions">
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography variant="h5" gutterBottom>
                        Permissions
                        <Divider />
                      </Typography>
                    </Grid>
                    {/* ────────────────────────────────────────────────────────────── */}
                    {/* ─── Determine Permission                                       */}
                    {/* ────────────────────────────────────────────────────────────── */}
                    <Grid item data-testid="editPermissionsFolderMode">
                      <PermissionOptions
                        control={control}
                        folderData={singleFolderData}
                        folderDataPermissions={folderPermissions.folderDataPermissions}
                        handleChange={handleChange}
                        inheritPermissions={
                          folderPermissions.inheritPermissions ? 'inherits' : 'own'
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Table
                      sx={{ minWidth: 650 }}
                      aria-label="permissions table"
                      data-testid="editPermissionsTable"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Group</TableCell>
                          {folderPermissions.folderDataPermissions?.permissionsList?.map(
                            (permission) => (
                              <TableCell
                                align="center"
                                key={`row-${permission}`}
                                sx={{ textTransform: 'capitalize' }}
                              >
                                {permission}
                              </TableCell>
                            )
                          )}
                        </TableRow>
                      </TableHead>
                      <PermissionsTable
                        control={control}
                        folderDataPermissions={folderPermissions.folderDataPermissions}
                        disabled={folderPermissions.inheritPermissions}
                      />
                    </Table>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button data-testid="editFolderCancelButton" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={handleConfirm}
            data-testid="editFolderSubmitButton"
            disabled={!formState.isDirty}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditFolder;

EditFolder.propTypes = {
  folderData: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      description: PropTypes.string,
    }),
    PropTypes.array,
  ]),
  children: PropTypes.node.isRequired,
};
