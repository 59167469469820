// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useCallback, useState } from 'react';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MuiList from '@mui/material/List';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import AddIcon from '@mui/icons-material/Add';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import CreateFolder from './CreateFolder';
import UploadDocument from './UploadDocument';

function SidebarActions() {
  const [anchorElement, setAnchorElement] = useState(null);

  const theme = useTheme();

  const handleClose = useCallback(() => setAnchorElement(null), []);
  const handleClick = useCallback((event) => {
    setAnchorElement(event.currentTarget);
  }, []);

  return (
    <>
      <Button
        data-testid="new-button"
        onClick={handleClick}
        aria-haspopup="true"
        variant="contained"
        startIcon={<AddIcon sx={{ color: theme.sidebar.button.text }} />}
        sx={{
          background: theme.sidebar.button.background,
          borderRadius: '1.25rem',
          color: theme.sidebar.button.text,
          ml: 2,
          mt: 2,
          height: '3.5rem',
          width: '6.25rem',
          textTransform: 'none',
          fontSize: '0.875rem',
        }}
      >
        New
      </Button>
      <Menu
        keepMounted
        onClick={handleClose}
        anchorEl={anchorElement}
        open={!!anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <MuiList>
          <UploadDocument />
          <CreateFolder />
        </MuiList>
      </Menu>
    </>
  );
}

export default SidebarActions;
