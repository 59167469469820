/* eslint-disable react/forbid-prop-types */
// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Checkbox from '@mui/material/Checkbox';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

function PermissionsTable({ control, disabled, folderDataPermissions }) {
  return (
    <TableBody>
      {folderDataPermissions?.groups?.map((group) => (
        <TableRow key={group.name} sx={{ 'td, th': { border: 0 } }}>
          <TableCell component="th" scope="row">
            {group.name}
          </TableCell>
          {folderDataPermissions?.permissionsList.map((permission) => {
            const initialCheckedStatus = folderDataPermissions.permissions[group._id][permission];

            return (
              <TableCell align="center" key={`box-${permission}`}>
                <Controller
                  name={`permissions.${group._id}.${permission}`}
                  control={control}
                  defaultValue={initialCheckedStatus}
                  shouldUnregister // if we don't unregister selected values will persist after reset!
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Checkbox
                      disabled={disabled}
                      onBlur={onBlur} // notify when input is touched
                      onChange={onChange} // send value to hook form
                      checked={disabled ? initialCheckedStatus : value} // if permission table gets disabled, revert all changes, but keep data for if they switch back
                      inputRef={ref}
                    />
                  )}
                />
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </TableBody>
  );
}

export default PermissionsTable;

PermissionsTable.propTypes = {
  control: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  folderDataPermissions: PropTypes.object,
};
