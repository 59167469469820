/* eslint-disable no-param-reassign */
// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import axios from '../AxiosInstance';
import useInterval from '../useInterval';

function useNestedItems(ids) {
  const [contents, setContents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const savedController = useRef();

  const getContents = useCallback(async () => {
    savedController.current = new AbortController();
    setIsLoading(true);
    // grab all folders and documents inside of specified folders
    try {
      const folderContent = await Promise.allSettled(
        ids.map(async (id) =>
          axios.get(`/api/folders/contents/${id}`, { signal: savedController.current.signal })
        )
      );

      // merge all given folders' contents into a single array
      const mergedContents = folderContent.map((item) => item.value.data);

      // add id to include parity with the rest of the front end
      mergedContents.forEach((item) => {
        item.id = item._id;
        item.contents.folders.forEach((folder) => {
          folder.id = folder._id;
        });
        item.contents.documents.forEach((document) => {
          document.id = document._id;
        });
      });
      setContents(mergedContents);
      setIsLoading(false);
    } catch (error) {
      // ignore the error
    }
  }, [ids]);

  // refetch data every thirty seconds to fight stale data
  useInterval(getContents, 30000);

  useEffect(() => {
    getContents();

    return () => savedController.current.abort();
  }, [getContents, ids]);

  return { contents, isLoading };
}

export default useNestedItems;

useNestedItems.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.number),
};
