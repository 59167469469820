// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useCallback, useContext } from 'react';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Grid from '@mui/material/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import ArchiveIcon from '@mui/icons-material/ArchiveOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import MoveIcon from '@mui/icons-material/DriveFileMoveOutlined';
import UnarchiveIcon from '@mui/icons-material/UnarchiveOutlined';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import ArchiveAction from './Archive';
import EditAction from './EditFolder';
import DeleteAction from './Delete';
import MoveAction from './Move';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Contexts
// ───────────────────────────────────────────────────────────────────────────
import FolderDataContext from '../../contexts/FolderDataContext';

function SelectedItemActions() {
  // ───────────────────────────────────────────────────────────────────────────
  // ─── Contexts and State
  // ───────────────────────────────────────────────────────────────────────────
  const { selectedItems, setSelectedItems } = useContext(FolderDataContext);

  // ───────────────────────────────────────────────────────────────────────────
  // ─── Handlers
  // ───────────────────────────────────────────────────────────────────────────
  const handleClearSelectedItems = useCallback(() => setSelectedItems([]), [setSelectedItems]);

  return (
    <Grid style={{ paddingLeft: '3%' }} container alignItems="center">
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      {/* ─── Clear Selected Items                                                    */}
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      <Grid item>
        <Tooltip title="Clear Selection">
          <span>
            <ListItemButton
              onClick={handleClearSelectedItems}
              sx={{
                borderRadius: '50%',
                width: '3em',
                height: '3em',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ClearIcon sx={{ color: 'text.secondary' }} />
            </ListItemButton>
          </span>
        </Tooltip>
      </Grid>

      {/* ─────────────────────────────────────────────────────────────────────────── */}
      {/* ─── Selected Count                                                          */}
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      <Grid item sx={{ verticalAlign: 'middle' }}>
        {selectedItems.length} selected
      </Grid>

      {/* ─────────────────────────────────────────────────────────────────────────── */}
      {/* ─── Selected Item Actions                                                   */}
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      <Grid item sx={{ pl: '2%' }}>
        <Stack justifyContent="center" direction="row" spacing={-2}>
          <MoveAction folderData={selectedItems}>
            <MoveIcon sx={{ color: 'text.secondary' }} data-testid="appbar-move-action" />
          </MoveAction>
          <DeleteAction folderData={selectedItems}>
            <DeleteIcon sx={{ color: 'text.secondary' }} data-testid="appbar-delete-action" />
          </DeleteAction>
          <ArchiveAction folderData={selectedItems}>
            {selectedItems[0]?.archived ? (
              <UnarchiveIcon sx={{ color: 'text.secondary' }} data-testid="appbar-restore-action" />
            ) : (
              <ArchiveIcon sx={{ color: 'text.secondary' }} data-testid="appbar-archive-action" />
            )}
          </ArchiveAction>
          <EditAction folderData={selectedItems}>
            <EditIcon sx={{ color: 'text.secondary' }} data-testid="appbar-edit-action" />
          </EditAction>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default SelectedItemActions;
