/* eslint-disable no-console */
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import axios from '../AxiosInstance';

// ALL widgets must have a unique ID
const ID = 'NextDocumentNumber';

// default widget size params and options
const Options = {
  h: 1,
  w: 1,
  minW: 1,
  minH: 1,
  isResizable: false,
  i: ID,
};

/**
 * A widget displaying the users next document number
 */
function NextDocumentNumberWidget() {
  const [nextDocNumber, setNextDocNumber] = useState();

  useEffect(() => {
    axios
      .get('/api/documents/get-next-id')
      .then(({ data }) => {
        setNextDocNumber(data.number);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // include x and y since they are provided automatically by WidgetDashboard
  return (
    <Card sx={{ height: '100%', width: '100%' }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Next Document Number
        </Typography>
        <Typography variant="body2">
          {nextDocNumber}
          {!nextDocNumber && 'Loading...'}
        </Typography>
      </CardContent>
    </Card>
  );
}

/**
 * This widget preview gets displayed on the widget settings page
 *
 * Preview widgets have a form factor of width 270px height 120px
 * and return only the widget content (no card/box/paper, kind of tags)
 */
function NextDocumentNumberWidgetPreview() {
  const [nextDocNumber, setNextDocNumber] = useState();

  useEffect(() => {
    axios
      .get('/api/documents/get-next-id')
      .then(({ data }) => {
        setNextDocNumber(data.number);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <Typography variant="body2">
      {nextDocNumber}
      {!nextDocNumber && 'Loading...'}
    </Typography>
  );
}

export { Options, ID, NextDocumentNumberWidget, NextDocumentNumberWidgetPreview };
