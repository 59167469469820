// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Pages
// ───────────────────────────────────────────────────────────────────────────
import ErrorPage from '../pages/404';
import Loading from '../pages/Loading';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Contexts
// ───────────────────────────────────────────────────────────────────────────
import UserContext from '../contexts/UserContext';

/**
 * A wrapper component that checks if the user is logged in
 * and has the correct roles to access the page.
 *
 * @param {Array} roles - The roles that the user must have to access the page, can leave empty
 * @param {React.Component} page - The page to render if the user is logged in and has the correct roles
 */
function ProtectedRoute({ roles = [], page }) {
  const { isAuthenticated, isLoading } = useAuth0();
  const { user } = useContext(UserContext);

  // if user is not logged in
  if (!isAuthenticated) {
    const AuthenticatedPage = withAuthenticationRequired(page);
    return <AuthenticatedPage />;
  }
  // if the user is loading
  if (isLoading || !user) return <Loading />;

  // if the user does not have the correct roles
  if (roles.length && !user.groups.some(({ name }) => roles.includes(name))) return <ErrorPage />;

  return page;
}

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
  page: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
