import Autocomplete from '@mui/material/Autocomplete';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';

// ALL widgets must have a unique ID
const ID = 'GoToSpecificDocumentNumber';

// default widget size and options
const Options = {
  h: 1,
  w: 1,
  minH: 1,
  minW: 1,
  isResizable: false,
  i: ID,
};

/**
 * A widget that allows you to type in an ID and go straight to the document
 */
function GoToSpecificDocumentWidget() {
  // eslint-disable-next-line no-unused-vars
  const [documents, setDocuments] = useState([]);

  // TODO: Probably remove all the autocomplete machinery
  // get all documents to for autocomplete (will need to be changed in future)
  /* useEffect(() => {
    (async () => {
      await axios
        .get('/api/documents')
        .then(({ data }) => {
          setDocuments(data);
        })
        .catch((error) => {
          console.error(error);
        });
    })();
  }, []); */

  return (
    <Card sx={{ height: '100%', width: '100%' }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Go To Specific Document
        </Typography>
        <Autocomplete
          freeSolo
          id="document"
          disableClearable
          options={documents.map(({ _id }) => _id.toString())}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Documents"
              InputProps={{
                ...params.InputProps,
                type: 'search',
              }}
            />
          )}
        />
      </CardContent>
    </Card>
  );
}

/**
 * This widget preview gets displayed on the widget settings page
 *
 * Preview widgets have a form factor of width 270px height 120px
 * and return only the widget content (no card/box/paper, kind of tags)
 */
function GoToSpecificDocumentWidgetPreview() {
  // this is a preview, and should not be fully functional
  return (
    <Autocomplete
      freeSolo
      id="document"
      disableClearable
      options={[]}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Documents"
          InputProps={{
            ...params.InputProps,
            type: 'search',
          }}
        />
      )}
    />
  );
}

export { Options, ID, GoToSpecificDocumentWidget, GoToSpecificDocumentWidgetPreview };
