// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useCallback, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import MenuIcon from '@mui/icons-material/Menu';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import ProfileMenu from './navbar/ProfileMenu';
import ResponsiveDrawer from './common/ResponsiveDrawer';
import Search from './navbar/Search';
import Sidebar from './navbar/Sidebar';
import TZLogoBlack from '../assets/tz-logo-black.svg';
import TZLogoWhite from '../assets/tz-logo-white.svg';
import useRefThreshold from './hooks/useRefThreshold';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Contexts
// ───────────────────────────────────────────────────────────────────────────
import { AlertMessageProvider } from '../contexts/AlertMessageContext';
import { FolderDataProvider } from '../contexts/FolderDataContext';

const REF_WIDTH = 600;
const EDGE_Z_INDEX = 1200;

/**
 * Wrapper component for each page
 *
 * @param {Array.<React.Component>} children - all data to be rendered
 */
function Layout({ children }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const { pathname } = useLocation();
  const handleToggle = useCallback(() => setOpen((prev) => !prev), []);

  const inBrowse = useMemo(() => pathname.includes('/browse/'), [pathname]);
  const ref = useRef(null);
  const isMobile = useRefThreshold(ref, REF_WIDTH);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  return (
    <Box sx={{ height: '100vh' }}>
      <AlertMessageProvider
        successMessage={successMessage}
        setSuccessMessage={setSuccessMessage}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      >
        <FolderDataProvider>
          <CssBaseline />
          <Grid container spacing={2} alignItems="center" justifyContent="space-between">
            {/* ─────────────────────────────────────────────────────────────────────────── */}
            {/* ─── Logo or Hamburger                                                       */}
            {/* ─────────────────────────────────────────────────────────────────────────── */}
            <Grid item xs={0} sm={3} sx={{ display: { xs: 'none', sm: 'block' } }}>
              <img
                src={prefersDarkMode ? TZLogoWhite : TZLogoBlack}
                alt="The logo of TZ Medical"
                height="80vh"
              />
            </Grid>
            <Grid item xs={1} sm={0} sx={{ ml: 2, mt: 2, display: { xs: 'block', sm: 'none' } }}>
              <IconButton
                onClick={handleToggle}
                // default drawer z-index is 1200, so keep it higher and hide it when the drawer is open
                sx={{ zIndex: open ? EDGE_Z_INDEX - 1 : EDGE_Z_INDEX + 1 }}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            {/* ─────────────────────────────────────────────────────────────────────────── */}
            {/* ─── Search Bar                                                              */}
            {/* ─────────────────────────────────────────────────────────────────────────── */}
            <Grid item xs={8} sm={5} sx={{ mt: 2 }}>
              {inBrowse && <Search />}
            </Grid>
            {/* ─────────────────────────────────────────────────────────────────────────── */}
            {/* ─── Profile Menu                                                            */}
            {/* ─────────────────────────────────────────────────────────────────────────── */}
            <Grid item xs={2} sm={4} sx={{ textAlign: 'right', position: 'relative' }}>
              <ProfileMenu />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            alignContent="space-between"
            justifyContent="space-between"
            paddingTop="8"
          >
            {/* ─────────────────────────────────────────────────────────────────────────── */}
            {/* ─── Side Bar                                                                */}
            {/* ─────────────────────────────────────────────────────────────────────────── */}
            {/* set height to 93% to utilize the rest of the available height */}
            {/* set xs to 0 so the component still renders but 'hidden' so the swipeable drawer edge can still be activated */}
            <Grid item xs={0} sm={1.5}>
              <ResponsiveDrawer
                threshold={isMobile}
                open={open}
                onClose={handleToggle}
                onOpen={handleToggle}
              >
                <Sidebar />
              </ResponsiveDrawer>
            </Grid>
            {/* ─────────────────────────────────────────────────────────────────────────── */}
            {/* ─── Children                                                                */}
            {/* ─────────────────────────────────────────────────────────────────────────── */}
            <Grid item ref={ref} xs={12} sm={10.5}>
              {children}
            </Grid>
          </Grid>
        </FolderDataProvider>
      </AlertMessageProvider>
    </Box>
  );
}
export default Layout;

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};
