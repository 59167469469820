// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useConfirm } from 'material-ui-confirm';
import { useForm } from 'react-hook-form';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import ActionsListItemButton from '../common/ActionsListItemButton';
import Alert from '../Alert';
import axios from '../AxiosInstance';
import errorCodes from '../utils/error-codes';
import FormStringInput from '../FormStringInput';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Contexts
// ───────────────────────────────────────────────────────────────────────────
import AlertMessageContext from '../../contexts/AlertMessageContext';
import FolderDataContext from '../../contexts/FolderDataContext';
import UserContext from '../../contexts/UserContext';

function Archive({ folderData, children }) {
  const [alertMessage, setAlertMessage] = useState('');
  const [open, setOpen] = useState(false);

  const { control, handleSubmit, watch } = useForm();
  const { setSuccessMessage } = useContext(AlertMessageContext);
  const { navigateOrRefresh } = useContext(FolderDataContext);
  const { user } = useContext(UserContext);
  const confirm = useConfirm();

  const folderDataArray = useMemo(
    () => (Array.isArray(folderData) ? folderData : [folderData]),
    [folderData]
  );
  const hasPermission = useMemo(
    () => user.isAdmin || folderDataArray.some((item) => item.permissions.archive),
    [folderDataArray, user]
  );
  const isDisabled = useMemo(
    () => !hasPermission || folderDataArray.some((item) => !!item.archivedBy),
    [folderDataArray, hasPermission]
  );
  const tooltipTitle = useMemo(() => {
    if (!hasPermission) return 'User does not have permission to use this action';
    if (isDisabled) return 'Cannot restore child of archived item';
    if (folderDataArray.some((item) => item?.archived)) return 'Restore Item(s)';
    return 'Archive Item(s)';
  }, [folderDataArray, hasPermission, isDisabled]);

  // the archive action cannot be performed if the selected items are mixed - some archived, some not
  // so we can just check the first item as it is guaranteed to be the same as the rest
  const isArchived = useMemo(() => folderDataArray[0].archived, [folderDataArray]);
  const archiveTitle = useMemo(() => (isArchived ? 'Restore' : 'Archive'), [isArchived]);
  const archivalString = useMemo(() => (isArchived ? 'Restore' : 'Archival'), [isArchived]);

  // on submit, confirm the user is sure to unarchive all nested items, then submit the form
  const handleConfirm = useCallback(async () => {
    if (isArchived) {
      await confirm({
        title: 'Confirm Restore',
        description:
          'This folder and all of its subfolders and subdocuments will be unarchived, are you sure?',
        confirmationText: 'Restore',
        confirmationButtonProps: { 'data-testid': 'submit-confirm-restore' },
      });
    }
  }, [confirm, isArchived]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setAlertMessage('');
  };

  // on submit, archive the selected items, then close the dialog and refresh the view
  const onSubmit = useCallback(
    async ({ selected, reason }) => {
      await handleConfirm();
      try {
        const query = { archived: !isArchived };

        if (selected === 'duplicate') {
          query.duplicateOf = reason;
        } else {
          query.reason = reason;
        }

        await Promise.all(
          folderDataArray.map(
            async ({ type, id }) =>
              type === 'folder' && // SUMMIT-201 implements document archiving, for now we only archive folders
              axios.patch(`/api/${type}s/archive/${id}`, query)
          )
        );

        setSuccessMessage(`Successfully ${archiveTitle}d item(s)`);
        handleClose();
        // all items should have the same parentId, so we can just use the first item
        navigateOrRefresh(folderDataArray[0].parentId);
      } catch (error) {
        // on failure alert the user
        if (error.response) {
          setAlertMessage(
            errorCodes.ERROR_ARCHIVE_API.toString(
              error?.response?.data?.message || error.response?.data?.error
            )
          );
        } else {
          setAlertMessage(errorCodes.ERROR_ARCHIVE_INTERNAL.toString(error.message));
        }
      }
    },
    [archiveTitle, folderDataArray, handleConfirm, navigateOrRefresh, isArchived, setSuccessMessage]
  );

  return (
    <>
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      {/* ─── Display Action Button                                                   */}
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      <Tooltip title={tooltipTitle}>
        <span>
          <ActionsListItemButton onClick={handleOpen} disabled={isDisabled}>
            {children}
          </ActionsListItemButton>
        </span>
      </Tooltip>

      {/* ─────────────────────────────────────────────────────────────────────────── */}
      {/* ─── Display Dialog                                                          */}
      {/* ─────────────────────────────────────────────────────────────────────────── */}

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <Alert
          message={alertMessage}
          setMessage={setAlertMessage}
          severity="error"
          variant="alert"
          allowClose
        />
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          {isArchived ? <UnarchiveIcon color="secondary" /> : <ArchiveIcon color="secondary" />}
          &nbsp;{archiveTitle} {folderDataArray.length} item(s)
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={3}>
            <Grid item xs="auto" sx={{ width: 400 }} data-testid="archive-confirm-prompt">
              Confirming this will <u>{archiveTitle.toLowerCase()}</u> the following items:
              <List sx={{ maxHeight: 300, overflow: 'auto' }} data-testid="items-to-be-archived">
                {folderDataArray.map(({ id, name }) => (
                  <ListItem key={`to-be-archived-${id}`}>
                    {id} - {name}
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs>
              <Stack>
                {!isArchived && (
                  <>
                    <FormStringInput
                      control={control}
                      options={[
                        { id: 'duplicate', name: 'Duplicate' },
                        { id: 'other', name: 'Other' },
                      ]}
                      name="selected"
                      id="selected"
                      label={`Reason for ${archivalString}`}
                      defaultValue="other"
                      required
                    />
                    <br />
                  </>
                )}
                {/*
                 * if duplicate ask for an id to validate it against another known folder id
                 * if other a free text field is shown
                 */}
                <FormStringInput
                  control={control}
                  defaultValue=""
                  id="archive-extra"
                  label={
                    watch('selected') === 'duplicate'
                      ? 'ID of duplicate'
                      : `Reason for ${archivalString}`
                  }
                  name="reason"
                  rules={{
                    required: true,
                    // can only be numbers if selectedItem === 'duplicate'
                    pattern: watch('selected') === 'duplicate' ? /^\d+$/ : /.*/,
                  }}
                  textFieldOptions={
                    watch('selected') === 'duplicate'
                      ? { type: 'number' }
                      : { multiline: true, rows: 4 }
                  }
                />
              </Stack>
            </Grid>
            <Grid item />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            onClick={() => handleSubmit(onSubmit)()}
            data-testid="archive-submit"
          >
            {archiveTitle}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Archive;

Archive.propTypes = {
  folderData: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        type: PropTypes.oneOf(['folder', 'document']),
        parentId: PropTypes.number,
        archived: PropTypes.bool,
      })
    ),
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      type: PropTypes.oneOf(['folder', 'document']),
      parentId: PropTypes.number,
      archived: PropTypes.bool,
    }),
  ]),
  children: PropTypes.node.isRequired,
};
