import axios from 'axios';

const AxiosInstance = axios.create();
AxiosInstance.interceptors.request.use((config) => {
  try {
    const { body } = JSON.parse(
      localStorage.getItem(
        `@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::summit.tzmedical.io::openid profile email`
      )
    );

    const token = body.access_token;

    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch (err) {
    // if there is no session, do nothing, secure routes will clearly show the failure
  }

  return config;
});

export default AxiosInstance;
