/* eslint-disable no-param-reassign */
// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

function MoveBreadcrumbs({ crumbs, setCurrentFolder }) {
  const setFolder = React.useCallback((id) => setCurrentFolder(id), [setCurrentFolder]);

  // on hover, underline the crumb and change the cursor to a pointer
  const onMouseLeave = useCallback((event) => {
    event.target.style.textDecoration = 'underline';
    event.target.style.cursor = 'pointer';
  }, []);

  // on mouse out, reset the underline and cursor to default
  const onMouseOver = useCallback((event) => {
    event.target.style.textDecoration = 'none';
    event.target.style.cursor = 'default';
  }, []);

  const handleClick = useCallback((id) => setFolder(id), [setFolder]);

  return (
    <MuiBreadcrumbs aria-label="breadcrumb">
      {crumbs.map(({ _id, name }) => (
        <Link
          style={{ color: 'inherit', textDecoration: 'inherit' }}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          to={_id}
          key={_id}
          onClick={() => handleClick(_id)}
          data-testid={`breadcrumb-${_id}`}
        >
          {name}
        </Link>
      ))}
    </MuiBreadcrumbs>
  );
}
export default MoveBreadcrumbs;

MoveBreadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.number,
      name: PropTypes.node,
    })
  ),
  setCurrentFolder: PropTypes.func.isRequired,
};
