// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import NestedArrowIcon from '@mui/icons-material/SubdirectoryArrowRight';
import RightArrowIcon from '@mui/icons-material/KeyboardArrowRight';

function Content({ item, contents, level = 0 }) {
  return (
    <>
      {/* display the content with further staggering the more nested the item is */}
      <ListItem sx={{ pl: 4 * level, margin: 0 }}>
        <ListItemIcon>{level ? <NestedArrowIcon /> : <RightArrowIcon />}</ListItemIcon>
        {item.name} ({item.id})
      </ListItem>
      <List component="div" disablePadding>
        {contents
          // recursively display all the nested items
          .filter((f) => f.parentId === item.id)
          .map((i) => (
            <Content key={i.id} item={i} contents={contents} level={level + 1} />
          ))}
      </List>
    </>
  );
}

export default Content;

Content.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  contents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      parentId: PropTypes.number,
    })
  ),
  level: PropTypes.number,
};
