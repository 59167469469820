// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import axios from '../components/AxiosInstance';
import useInterval from '../components/useInterval';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Contexts
// ───────────────────────────────────────────────────────────────────────────
import AlertMessageContext from './AlertMessageContext';
import UserContext from './UserContext';

const FolderDataContext = createContext();

const REFRESH_INTERVAL_MS = 30 * 1_000;
export function FolderDataProvider({ children }) {
  // ───────────────────────────────────────────────────────────────────────────
  // ─── State
  // ───────────────────────────────────────────────────────────────────────────
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [currentFolder, setCurrentFolder] = useState({ permissions: {} });
  const [currentFolderId, setCurrentFolderId] = useState(1);
  const [folderContents, setFolderContents] = useState([]);
  const [folderDataPermissions, setFolderDataPermissions] = useState({});
  const [inheritPermissions, setInheritPermissions] = useState(true);
  const [loading, setLoading] = useState(false);
  const [originalFolderDataPerms, setOriginalFolderDataPerms] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedItemPermissions, setSelectedItemPermissions] = useState({
    archive: false,
    read: false,
    move: false,
    rename: false,
    write: false,
  });

  // ───────────────────────────────────────────────────────────────────────────
  // ─── Contexts & Hooks
  // ───────────────────────────────────────────────────────────────────────────
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { setErrorMessage } = useContext(AlertMessageContext);
  const { user } = useContext(UserContext);

  const refreshImmediately = useMemo(() => pathname, [pathname]);
  const savedController = useRef();

  // ───────────────────────────────────────────────────────────────────────────
  // ─── Handlers
  // ───────────────────────────────────────────────────────────────────────────
  const resetFolderDataPermissions = useCallback(
    () => setFolderDataPermissions(originalFolderDataPerms),
    [originalFolderDataPerms]
  );

  const updateFolderData = useCallback(async () => {
    if (!pathname.includes('/browse')) return false;

    const id = Number(pathname.split('/browse/')[1]);

    setLoading(true);

    if (id !== currentFolderId) {
      setCurrentFolderId(id);
      setSelectedItems([]);
    }

    try {
      const [folder, contents, crumbs, folderPermissions] = await Promise.all([
        axios.get(`/api/folders/${id}`, {
          signal: savedController?.current?.signal,
        }),
        axios.get(`/api/folders/list/${id}`, {
          signal: savedController?.current?.signal,
        }),
        axios.get(`/api/folders/breadcrumbs/${id}`, {
          signal: savedController?.current?.signal,
        }),
        axios
          .get(`/api/folders/${id}/editpermissions`, {
            signal: savedController?.current?.signal,
          })
          // ignore error but keep in promise all to not mess up react's render grouping
          .catch(() => {}),
      ]);
      const newFolderContents = [
        ...contents.data.subFolders.map((f) => ({ ...f, type: 'folder', id: f._id })),
        ...contents.data.documents.map((d) => ({ ...d, type: 'document', id: d._id })),
      ];
      setCurrentFolder({ ...folder.data, id: folder.data._id, type: 'folder' });
      setFolderContents(newFolderContents);
      setBreadcrumbs(crumbs.data);
      if (selectedItems.length) {
        setSelectedItems((prev) =>
          prev.map((item) => newFolderContents.find((content) => content.id === item.id))
        );
      }

      if (folderPermissions?.data) {
        setFolderDataPermissions(folderPermissions.data);
        setOriginalFolderDataPerms(folderPermissions.data);
        setInheritPermissions(folderPermissions.data.isInherited);
      }
    } catch (error) {
      if (error.response?.status === 403) {
        setErrorMessage(`Folder (${id}) does not exist`);
      } else {
        setErrorMessage(error.message);
      }
    }

    setLoading(false);
    return () => savedController.current.abort();
  }, [currentFolderId, pathname, selectedItems.length, setErrorMessage]);

  // if the new id is different than the current folder: navigate
  // if the folder is the same just refresh the data
  const navigateOrRefresh = useCallback(
    (id) => {
      if (id !== currentFolderId) {
        navigate(`/browse/${id}`);
      } else {
        updateFolderData();
      }
    },
    [currentFolderId, navigate, updateFolderData]
  );

  // determine user permissions
  useEffect(() => {
    setSelectedItemPermissions(
      selectedItems
        // map the selectedItems array to only include the permissions object
        .map((item) => item.permissions)
        // reduce the selectedItems array to only include the permissions object
        .reduce(
          (acc, curr) => ({
            archive: acc.archive && curr.archive,
            move: acc.move && curr.move,
            read: acc.read && curr.read,
            rename: acc.rename && curr.rename,
            write: acc.write && curr.write,
          }),
          // set the initial values for the permissions object
          {
            archive: true,
            move: true,
            read: true,
            rename: true,
            write: true,
          }
        )
    );
  }, [selectedItems, user]);

  // public items from context
  const memoizedValue = useMemo(
    () => ({
      breadcrumbs,
      currentFolder,
      folderContents,
      folderPermissions: {
        folderDataPermissions,
        inheritPermissions,
        resetFolderDataPermissions,
        setFolderDataPermissions,
        setInheritPermissions,
      },
      loading,
      navigateOrRefresh,
      selectedItemPermissions,
      selectedItems,
      setSelectedItems,
      updateFolderData,
    }),
    [
      breadcrumbs,
      currentFolder,
      folderContents,
      folderDataPermissions,
      inheritPermissions,
      loading,
      navigateOrRefresh,
      resetFolderDataPermissions,
      selectedItemPermissions,
      selectedItems,
      updateFolderData,
    ]
  );

  // update current folder's data every thirty seconds or when triggered
  useInterval(updateFolderData, REFRESH_INTERVAL_MS, refreshImmediately);

  return <FolderDataContext.Provider value={memoizedValue}>{children}</FolderDataContext.Provider>;
}

export default FolderDataContext;

FolderDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
