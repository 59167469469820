/* eslint-disable react/forbid-prop-types */
// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function PermissionOptions({
  control,
  folderData,
  folderDataPermissions,
  handleChange,
  inheritPermissions,
}) {
  return (
    <Controller
      name="permissionsOption"
      control={control}
      shouldUnregister
      render={({ field: { onBlur, onChange, ref } }) => (
        <Select
          disabled={folderData.id === 1}
          data-testid="permission-options-dropdown"
          value={inheritPermissions}
          onBlur={onBlur} // notify when input is touched
          inputRef={ref}
          onChange={(event) => {
            handleChange(event);
            onChange(event);
          }}
        >
          {/* no one should be able to change permission direction of root folder! */}
          <MenuItem value="inherits" data-testid="permission-options-inherit">
            This folder inherits its permissions from{' '}
            {folderDataPermissions.inheritedFrom?._id === 1
              ? 'global permissions'
              : folderDataPermissions.inheritedFrom?.name}
          </MenuItem>
          <MenuItem value="own" data-testid="permission-options-own">
            This folder{' '}
            {folderData.id === 1
              ? 'always specifies the global permissions'
              : 'specifies its own permissions'}
          </MenuItem>
        </Select>
      )}
    />
  );
}

export default PermissionOptions;

PermissionOptions.propTypes = {
  control: PropTypes.object.isRequired,
  folderData: PropTypes.shape({
    id: PropTypes.number,
  }),
  folderDataPermissions: PropTypes.shape({
    isInherited: PropTypes.bool,
    inheritedFrom: PropTypes.shape({
      _id: PropTypes.number,
      name: PropTypes.string,
    }),
  }),
  handleChange: PropTypes.func.isRequired,

  // corresponds to the dropdown options
  inheritPermissions: PropTypes.oneOf(['own', 'inherits']),
};
