/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import UserContext from '../../contexts/UserContext';
import { RegisteredWidgets } from './RegisteredWidgets';
import { ApplyGridItemDefaults } from './WidgetUtils';

const ResponsiveGridLayout = WidthProvider(Responsive);

/**
 * A dashboard layout which displays widgets saved in user metadata
 */
function WidgetDashboard() {
  const { user } = useContext(UserContext);
  const [userWidgets, setUserWidgets] = useState([]);
  const [bp, setBp] = useState(null);

  // handle creation of widgets
  useEffect(() => {
    if (!user) return;

    const widgets = user.metadata.dashboard_widgets || {};

    const tmpBuf = [];
    const tmpLay = [];
    for (const id in widgets) {
      const item = RegisteredWidgets[id];
      const Widget = item.widget;
      // metadata options override widget defaults...
      // and grid item defaults fills in the missing props with defaults
      const options = ApplyGridItemDefaults({
        ...item.options,
        ...widgets[id],
      });

      tmpLay.push(options);

      // bp (breakpoint) is here to do re-renders to workaround a bug where it doesn't
      // return to proper size after going through multiple breakpoints
      // when this does get fixed, please remove the `bp` from this as it causes extra re-renders
      // https://github.com/react-grid-layout/react-grid-layout/issues/1663
      tmpBuf.push(
        <div key={`${bp}${id}`} data-grid={{ ...options }}>
          <Widget />
        </div>
      );
    }

    setUserWidgets(tmpBuf);
  }, [user, bp]);

  const layoutCodes = ['lg', 'md', 'sm', 'xs', 'xxs'];
  const onDragStop = (newlayout) => {
    // save the widget layout state for restore later

    // item.i is the only way we know what widget it is, but it's prefixed by a layout code
    // we must strip it
    const fixedLayout = {};
    for (const widget of newlayout) {
      // i = <layoutcode><id> ; sometimes 'null'
      let id;
      if (widget.i.startsWith('null')) {
        // probably a bug in the lib
        id = widget.i.substring(4, widget.i.length);
      } else {
        for (const code of layoutCodes) {
          if (widget.i.startsWith(code)) {
            id = widget.i.substring(code.length, widget.i.length);
            break;
          }
        }
      }

      fixedLayout[id] = widget;
    }

    user.metadata.dashboard_widgets = fixedLayout;
    user.metadata.save();
  };

  if (!user) return;

  return (
    <ResponsiveGridLayout
      className="dashboard-widget-layout"
      // Note: If any content is added to the page, or other design ideas changed
      //       It might impact the breakpoints and columns (cols) such that resizing won't
      //       transition the widgets as smoothly as before across breakpoints, or it could conflict
      //       with mui breakpoints that other content is using. This will very likely need
      //       adjustments in the future as needed.
      //       Please make sure to check these and keep them up to date
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      cols={{ lg: 6, md: 5, sm: 3, xs: 2, xxs: 1 }}
      autoSize
      onDragStop={onDragStop}
      onBreakpointChange={setBp}
      // allow freeform vertical placement
      compactType={null}
    >
      {userWidgets}
    </ResponsiveGridLayout>
  );
}

export default WidgetDashboard;
