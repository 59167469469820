// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { capitalize } from '@mui/material/utils';
import { useForm } from 'react-hook-form';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import UploadDocumentIcon from '@mui/icons-material/UploadFileRounded';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import Alert from '../Alert';
import axios from '../AxiosInstance';
import errorCodes from '../utils/error-codes';
import FormDocumentInput from '../FormDocumentInput';
import FormStringInput from '../FormStringInput';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Context
// ───────────────────────────────────────────────────────────────────────────
import AlertMessageContext from '../../contexts/AlertMessageContext';
import FolderDataContext from '../../contexts/FolderDataContext';
import UserContext from '../../contexts/UserContext';

function UploadDocument() {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [classifications, setClassifications] = useState([]);
  const { control, handleSubmit, getValues } = useForm();
  const { currentFolder, updateFolderData } = useContext(FolderDataContext);
  const { setSuccessMessage } = useContext(AlertMessageContext);
  const { user } = useContext(UserContext);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setAlertMessage('');
  }, [setOpen]);

  // on submit, upload the document classification, comment, and the document itself, then close the dialog and refresh the view
  const onSubmit = async ({ addDocumentName, classification, comment, document }) => {
    const formData = new FormData();
    formData.append('name', addDocumentName);
    formData.append('classification', classification);
    formData.append('comment', comment);
    formData.append('file', document);

    try {
      await axios.post(`/api/folders/createDocument/${currentFolder?._id}`, formData);

      setSuccessMessage(`Successfully uploaded ${addDocumentName}`);
      updateFolderData();
      handleClose();
    } catch (error) {
      // on failure alert the user
      if (error.response) {
        setAlertMessage(
          errorCodes.ERROR_UPLOAD_DOCUMENT_API.toString(
            error.response?.data?.message || error.response?.data?.error
          )
        );
      } else {
        setAlertMessage(errorCodes.ERROR_UPLOAD_DOCUMENT_INTERNAL.toString(error.message));
      }
    }
  };

  // define the classifications for the form
  useEffect(() => {
    const updateClassifications = async () => {
      try {
        const { data } = await axios.get(`/api/classifications`);
        setClassifications(data);
      } catch (error) {
        // ignore the error
      }
    };

    updateClassifications();
  }, [currentFolder]);

  return (
    <>
      <ListItemButton
        onClick={handleOpen}
        disabled={!user?.isAdmin || !currentFolder.permissions?.write}
        data-testid="upload-document-action"
      >
        <ListItemIcon>
          <UploadDocumentIcon sx={{ color: 'text.secondary' }} />
        </ListItemIcon>
        <ListItemText primary="Upload Document" />
      </ListItemButton>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
        data-testid="uploadDocumentAction"
      >
        <Alert
          message={alertMessage}
          setMessage={setAlertMessage}
          severity="error"
          variant="alert"
          allowClose
        />
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <UploadDocumentIcon color="secondary" />
          &nbsp;Upload Document to: {capitalize(currentFolder?.name || 'Item')}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <FormStringInput
                control={control}
                defaultValue=""
                label="New Document Name"
                name="addDocumentName"
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormStringInput
                control={control}
                defaultValue=""
                label="Classification"
                name="classification"
                options={classifications.map((type) => ({
                  ...type,
                  id: type._id,
                }))}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormStringInput control={control} defaultValue="" label="Comment" name="comment" />
            </Grid>
            <Grid item xs={12} sm={6} />
            <FormDocumentInput
              control={control}
              name="document"
              label="Drag & Drop a Document"
              rules={{
                required: {
                  value: !getValues().document,
                  message: 'Document is required',
                },
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmit(onSubmit)}>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UploadDocument;
