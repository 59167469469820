// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import InfoIcon from '@mui/icons-material/InfoOutlined';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import Breadcrumbs from '../components/Breadcrumbs';
import BrowserTable from '../components/BrowserTable';
import CurrentFolderMetadata from '../components/CurrentFolderMetadata';
import ResponsiveDrawer from '../components/common/ResponsiveDrawer';
import SelectedItemActions from '../components/browse-document-actions/SelectedItemActions';
import SidebarActions from '../components/browse-document-actions/SidebarActions';
import useRefThreshold from '../components/hooks/useRefThreshold';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Contexts
// ───────────────────────────────────────────────────────────────────────────
import FolderDataContext from '../contexts/FolderDataContext';
import PageContext from '../contexts/PageContext';

const MD_WIDTH = 900;

function BrowseDocuments() {
  const [open, setOpen] = useState(false);
  const { selectedItems } = useContext(FolderDataContext);
  const { setPageButtons } = useContext(PageContext);

  const ref = useRef(null);
  const isAtLeastMd = useRefThreshold(ref, MD_WIDTH);
  const handleToggle = useCallback(() => setOpen((prev) => !prev), []);

  // add actions to sidebar while in the browseDocuments page
  useEffect(() => {
    setPageButtons(<SidebarActions />);

    return () => setPageButtons(null);
  }, [setPageButtons]);

  return (
    <Grid container spacing={2} alignContent="stretch" justifyContent="space-between" px={2}>
      <Grid item xs={12} md={open ? 10 : 12}>
        <Paper elevation={0} ref={ref} sx={{ borderRadius: '1.25rem' }}>
          <Grid container alignContent="center" justifyContent="space-between">
            <Grid item xs={11}>
              <Breadcrumbs />
            </Grid>
            <Grid item xs={1} pt={2}>
              <IconButton onClick={handleToggle} data-testid="toggle-current-folder-panel">
                <InfoIcon />
              </IconButton>
            </Grid>
          </Grid>
          <div style={{ height: '1em', alignItems: 'center', paddingBottom: '1.5em' }}>
            <div style={{ visibility: selectedItems.length ? 'visible' : 'hidden' }}>
              {selectedItems.length ? <SelectedItemActions /> : null}
            </div>
          </div>
          <br />
          <BrowserTable isAtLeastMd={isAtLeastMd} />
        </Paper>
      </Grid>
      {/* set xs to 0 so the component still renders but 'hidden' so the swipeable drawer edge can still be activated */}
      <Grid item xs={0} md={open ? 2 : 0} sx={{ display: { md: open ? 'block' : 'none' } }}>
        <ResponsiveDrawer
          threshold={isAtLeastMd}
          open={open}
          onClose={handleToggle}
          onOpen={handleToggle}
          data-testid="current-folder-metadata"
          anchor="right"
        >
          <Paper
            elevation={0}
            sx={{
              borderRadius: '1.25rem',
              height: '100%',
              overflowX: 'none',
            }}
          >
            <CurrentFolderMetadata handleClose={handleToggle} />
          </Paper>
        </ResponsiveDrawer>
      </Grid>
    </Grid>
  );
}

export default BrowseDocuments;
