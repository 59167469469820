// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { useForm } from 'react-hook-form';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import MoveIcon from '@mui/icons-material/DriveFileMove';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import ActionsListItemButton from '../common/ActionsListItemButton';
import Alert from '../Alert';
import axios from '../AxiosInstance';
import Breadcrumbs from './MoveBreadcrumbs';
import errorCodes from '../utils/error-codes';
import FormStringInput from '../FormStringInput';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Context
// ───────────────────────────────────────────────────────────────────────────
import AlertMessageContext from '../../contexts/AlertMessageContext';
import FolderDataContext from '../../contexts/FolderDataContext';
import UserContext from '../../contexts/UserContext';

// Fast enough to be performant and let user type in without triggering, but not slow enough to take too long
// MAY need adjustment if this time isn't proper for all use-cases
const debounceMs = 350;

function Move({ folderData, children }) {
  // ───────────────────────────────────────────────────────────────────────────
  // Component state management
  // ───────────────────────────────────────────────────────────────────────────
  const { control, handleSubmit, setValue, trigger, formState } = useForm();
  const { navigateOrRefresh, updateFolderData } = useContext(FolderDataContext);
  const { setSuccessMessage } = useContext(AlertMessageContext);
  const { user } = useContext(UserContext);

  const [alertMessage, setAlertMessage] = useState('');
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [open, setOpen] = useState(false);
  const [targetFolderData, setTargetFolderData] = useState();

  const folderDataArray = useMemo(
    () => (Array.isArray(folderData) ? folderData : [folderData]),
    [folderData]
  );
  const hasPermission = useMemo(
    () => user.isAdmin || folderDataArray.some((item) => item.permissions.move),
    [folderDataArray, user.isAdmin]
  );
  const isDisabled = useMemo(
    () => !hasPermission || folderDataArray.some((item) => item?.id === 1),
    [folderDataArray, hasPermission]
  );
  const tooltipTitle = useMemo(() => {
    if (!hasPermission) return 'User does not have permission to use this action';
    if (isDisabled) return 'Cannot move root folder';
    return `Move item${folderDataArray.length > 1 ? 's' : ''}`;
  }, [folderDataArray.length, hasPermission, isDisabled]);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setAlertMessage('');
  }, []);

  // ───────────────────────────────────────────────────────────────────────────
  // A debounced onChange handler that triggers form validation
  // ───────────────────────────────────────────────────────────────────────────
  const debouncedChangeHandler = useMemo(
    (fieldName) =>
      debounce(async () => {
        await trigger(fieldName);
      }, debounceMs),
    [trigger]
  );

  // ───────────────────────────────────────────────────────────────────────────
  // Stop the invocation of the debounced function after unmounting
  // ───────────────────────────────────────────────────────────────────────────
  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  // ───────────────────────────────────────────────────────────────────────────
  // On submit, move the selected folders, then close the dialog and refresh the view
  // ───────────────────────────────────────────────────────────────────────────
  const onSubmit = async ({ parentId: _parentId }) => {
    // make sure it's a number
    const parentId = parseInt(_parentId, 10);

    // process selected items
    const failedItems = [];

    await Promise.all(
      folderDataArray.map(async ({ id, type, name }) => {
        try {
          await axios.patch(`/api/${type}s/${id}`, { parentId });
        } catch (error) {
          if (error.response) {
            setAlertMessage(
              errorCodes.ERROR_MOVE_API.toString(
                error.response?.data?.message || error.response?.data?.error
              )
            );
          } else {
            setAlertMessage(errorCodes.ERROR_MOVE_INTERNAL.toString(error?.message));
          }
          // we do not use this error, but we do need the failed ids
          failedItems.push(name);
        }
      })
    );

    if (failedItems.length > 0) {
      // stay on current folder, refresh page, and display error snackbar on page
      updateFolderData();
      // this error shares the same code as another error,
      // but this message is unique enough to distinguish between the two
      setAlertMessage(
        errorCodes.ERROR_MOVE_API_FAILURE.toString(
          `Some of the items you attempted to move failed. Please review the current folder for failed items:\n${failedItems.join(
            ', '
          )}`
        )
      );

      return;
    }

    // if control flow gets here, we have succeeded
    setSuccessMessage(`Successfully moved item(s) to: ${targetFolderData.name}`);
    // prevents it from showing up on subsequent pages
    setAlertMessage('');
    setOpen(false);
    // navigate from the current folder to the parent folder and refresh rhe view
    navigateOrRefresh(parentId);
  };

  return (
    <>
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      {/* ─── Display Action Button                                                   */}
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      <Tooltip title={tooltipTitle}>
        <span>
          <ActionsListItemButton onClick={handleOpen} disabled={isDisabled}>
            {children}
          </ActionsListItemButton>
        </span>
      </Tooltip>
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      {/* ─── Display Dialog                                                        */}
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" data-testid="moveDialog">
        <Alert
          message={alertMessage}
          setMessage={setAlertMessage}
          severity="error"
          variant="alert"
          allowClose
        />
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <MoveIcon color="secondary" />
          &nbsp;Move Item(s)
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid container item direction="column">
              <FormStringInput
                control={control}
                defaultValue=""
                label="Folder Id"
                name="parentId"
                rules={{
                  required: true,
                  validate: {
                    // cannot be empty input, and must be a number
                    validateInput: (v) => {
                      const folderId = Number(v);
                      if (!v.trim() || Number.isNaN(folderId)) {
                        return 'Folder id must be a number';
                      }
                      return true;
                    },

                    // check if target is invalid -> folder id cannot be a descendent
                    validateNotDescendant: async (v) => {
                      const folderId = Number(v);
                      try {
                        await Promise.allSettled(
                          // eslint-disable-next-line consistent-return
                          folderDataArray.map(async (item) => {
                            // descendant folder check is only valid for folders, not specific items
                            if (item.type === 'folder') {
                              // you can't move item/items from the same source to the same destination
                              if (folderId === item.id) {
                                return 'Cannot move to folder: source and destination folders are the same';
                              }

                              // you can't move an item inside itself
                              const { data } = await axios.get(
                                `/api/folders/isDescendant/${item.id}/${folderId}`
                              );
                              if (data.isDescendant) {
                                return `Cannot move folder ${item.id} to a folder that's a descendant of itself`;
                              }
                            }
                          })
                        );
                        if (folderDataArray.length === 1) {
                          // you can't move item/items from the same source to the same destination
                          if (folderId === folderDataArray[0].id) {
                            return 'Cannot move to folder: source and destination folders are the same';
                          }

                          // you can't move an item inside itself
                          const { data } = await axios.get(
                            `/api/folders/isDescendant/${folderDataArray[0].id}/${folderId}`
                          );
                          if (data.isDescendant) {
                            return "Cannot move source folder to a folder that's a descendant of itself";
                          }
                        }
                      } catch (e) {
                        // 403 = we don't have read permission
                        //
                        // no data to check against will be returned since it's disallowed. But this is OK! This means we don't have
                        // access to the target folder, so we know permission is denied! :D
                        if (e.response.status === 403) {
                          return 'You do not have permission to move to this folder';
                        }
                        return e.response.data.message || e.response.data.error;
                      }
                      return true;
                    },

                    // check that the target folder has permissions to move to
                    checkTargetPermissions: async (v) => {
                      const folderId = Number(v);
                      try {
                        // user has read access to folder, so query succeeds, but write is still required for them to move!
                        // no write access to target folder = no move permission
                        const { data: targetFolderDataResponse } = await axios.get(
                          `/api/folders/${folderId}`
                        );

                        if (!targetFolderDataResponse.permissions.write) {
                          return 'You do not have permission to move to this folder';
                        }

                        setTargetFolderData(targetFolderDataResponse);
                      } catch (e) {
                        return e.response.data.message || e.response.data.error;
                      }
                      return true;
                    },

                    // post validate success hooks - display the breadcrumbs!
                    postValidate: async (v) => {
                      try {
                        const breadcrumbData = await axios.get(`/api/folders/breadcrumbs/${v}`);

                        setBreadcrumbs(breadcrumbData.data);
                      } catch (e) {
                        setAlertMessage(
                          errorCodes.ERROR_MOVE_API_VALIDATE.toString(e.response.data.message)
                        );
                      }
                    },
                  },
                }}
                id="moveInput"
                onChange={() => debouncedChangeHandler('parentId')}
              />
            </Grid>
            <Grid container item direction="column">
              {formState.isValid && (
                <Breadcrumbs
                  crumbs={breadcrumbs}
                  setCurrentFolder={(_id) => {
                    // update the parentId form field to new id
                    setValue('parentId', `${_id}`, { shouldValidate: true });
                  }}
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAlertMessage('');
              setOpen(false);
              setBreadcrumbs([]);
            }}
            data-testid="moveCancel"
          >
            Cancel
          </Button>
          <Button
            data-testid="moveSubmit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={!formState.isValid || !formState.touchedFields}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Move;

Move.propTypes = {
  folderData: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
      })
    ),
    PropTypes.object,
  ]),
  children: PropTypes.node.isRequired,
};
