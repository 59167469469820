// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useCallback, useContext, useState } from 'react';
import { capitalize } from '@mui/material/utils';
import { useForm } from 'react-hook-form';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import CreateFolderIcon from '@mui/icons-material/DriveFolderUpload';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import Alert from '../Alert';
import axios from '../AxiosInstance';
import FormStringInput from '../FormStringInput';
import errorCodes from '../utils/error-codes';
import validateNameInput from '../utils/validateNameInput';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Contexts
// ───────────────────────────────────────────────────────────────────────────
import AlertMessageContext from '../../contexts/AlertMessageContext';
import FolderDataContext from '../../contexts/FolderDataContext';
import UserContext from '../../contexts/UserContext';

function CreateFolder() {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { setSuccessMessage } = useContext(AlertMessageContext);
  const { currentFolder, updateFolderData } = useContext(FolderDataContext);
  const { user } = useContext(UserContext);
  const { handleSubmit, control, formState } = useForm({
    mode: 'onTouched',
    defaultValues: { name: '', description: '' },
  });

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => {
    setOpen(false);
    setAlertMessage('');
  }, [setOpen]);

  const onSubmit = async ({ name, description }) => {
    try {
      await axios.post(`/api/folders/${currentFolder.id}`, {
        name,
        description,
      });

      // if successful alert the user, refresh the view, and close the dialog
      setSuccessMessage(`Folder ${name} created successfully`);
      handleClose();
      updateFolderData();
    } catch (error) {
      // on failure alert the user
      if (error.response) {
        setAlertMessage(
          errorCodes.ERROR_CREATE_FOLDER_API_ERROR.toString(
            error.response?.data?.message || error.response?.data?.error
          )
        );
      } else {
        setAlertMessage(errorCodes.ERROR_CREATE_FOLDER_INTERNAL.toString(error.message));
      }
    }
  };

  return (
    <>
      <ListItemButton
        onClick={handleOpen}
        disabled={!user?.isAdmin || !currentFolder.permissions?.write}
        data-testid="create-folder-action"
      >
        <ListItemIcon>
          <CreateFolderIcon sx={{ color: 'text.secondary' }} />
        </ListItemIcon>
        <ListItemText primary="Create folder" />
      </ListItemButton>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <Alert
          message={alertMessage}
          setMessage={setAlertMessage}
          severity="error"
          title="error"
          variant="alert"
        />
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          <CreateFolderIcon color="secondary" />
          &nbsp;Add Folder To: {capitalize(currentFolder?.name || '')}
        </DialogTitle>
        <Divider sx={{ bgcolor: 'secondary.main' }} />
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <FormStringInput
                control={control}
                name="name"
                label="Folder Name"
                id="folderName"
                rules={{
                  required: 'A name is required to create a folder',
                  validate: {
                    validateNameInput: (value) => validateNameInput(value, 'Name'),
                  },
                }}
                aria-invalid={formState.errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormStringInput
                control={control}
                name="description"
                label="Folder Description"
                id="folderDescription"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            disabled={!formState.isValid}
            data-testid="createFolderSubmit"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CreateFolder;
