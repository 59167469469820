// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { createContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const UserContext = createContext();

/**
 * A component that wraps the user context provider.
 * This is a single nested property
 *
 * This can be used similarly as
 * ```js
 * <UserProvider>
 *  ...
 * </UserProvider>
 * ```
 *
 * @param {object} user - the `UserProfile` object
 * @param {React.FC} children
 */
export function UserProvider({ user, children }) {
  const memoizedValue = useMemo(() => ({ user }), [user]);
  return <UserContext.Provider value={memoizedValue}>{children}</UserContext.Provider>;
}

export default UserContext;

UserProvider.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  user: PropTypes.object,
  children: PropTypes.node.isRequired,
};
