// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Button from '@mui/material/Button';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import FolderDataContext from '../contexts/FolderDataContext';

function Breadcrumbs() {
  const { breadcrumbs } = useContext(FolderDataContext);

  return (
    <MuiBreadcrumbs aria-label="breadcrumb" separator={<NavigateNextIcon />}>
      {breadcrumbs.map((crumb) => (
        <Button
          component={Link}
          // prefixed by 'crumb' as table component also uses _id as a key
          key={`crumb-${crumb._id}`}
          sx={{
            color: 'inherit',
            textDecoration: 'inherit',
            fontSize: '1.5rem',
            textTransform: 'none',
          }}
          to={`/browse/${crumb._id}`}
          data-testid={`breadcrumb-${crumb._id}`}
        >
          {crumb.name}
        </Button>
      ))}
    </MuiBreadcrumbs>
  );
}

export default Breadcrumbs;
