/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import UserContext from '../../contexts/UserContext';

import { RegisteredWidgets } from './RegisteredWidgets';
import './WidgetSettings.css';

function WidgetSettings() {
  const { user } = useContext(UserContext);

  // get all enabled/disabled id's
  const [enabled, setEnabled] = useState({});
  // whether or not the dialog is open
  const [open, setOpen] = useState(false);
  // the complete widget list to reference from
  const [widgets, setWidgets] = useState([]);
  // the filtered widget list
  const [filtered, setFiltered] = useState([]);
  // whether or not to display the autocomplete dropdown
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  // input value of autocomplete text box
  const [inputValue, setInputValue] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // handle dialog open
  const handleClickOpen = () => () => {
    setOpen(true);
  };

  // handle dialog close
  const handleClose = () => {
    setOpen(false);
  };

  // get all registered widgets, set to false, then if user has them enabled, enable them
  useEffect(() => {
    const tmpBuf = {};
    for (const key in RegisteredWidgets) {
      tmpBuf[key] = false;
    }

    if (user) {
      for (const key in user.metadata.dashboard_widgets) {
        tmpBuf[key] = true;
      }
    }

    setEnabled(tmpBuf);
  }, [user, open]);

  // generate all widget previews
  useEffect(() => {
    if (!user) return;

    const tmpBuf = [];

    // enable or disable a specific widget
    const setWidgetState = (id, state) => {
      // update checkbox
      setEnabled({ ...enabled, [id]: state });

      if (!user.metadata.dashboard_widgets) {
        user.metadata.dashboard_widgets = {};
      }

      if (state) {
        user.metadata.dashboard_widgets[id] = {
          x: 0,
          y: 0,
        };
        user.metadata.save();
      } else {
        delete user.metadata.dashboard_widgets[id];
        user.metadata.save();
      }
    };

    for (const key in RegisteredWidgets) {
      const item = RegisteredWidgets[key];
      const Preview = item.widgetPreview;

      tmpBuf.push({
        title: item.title,
        element: (
          <div className="grid-item" key={key}>
            <Card className="card">
              <Checkbox
                checked={enabled[key]}
                onChange={(event) => {
                  setWidgetState(key, event.target.checked);
                }}
                // hide the default styling of the checkbox so we can do our own
                icon={<></>}
                checkedIcon={<></>}
                disableRipple
                className="toggle-checkbox"
              />

              <CardContent sx={{ paddingTop: 0 }}>
                <Typography variant="subtitle2" sx={{ paddingBottom: '5px' }} className="title">
                  {item.title}
                </Typography>

                <Preview />
              </CardContent>
            </Card>
          </div>
        ),
      });
    }

    setWidgets(tmpBuf);
    setFiltered(tmpBuf.map((widget) => widget.element));
  }, [enabled, user]);

  // handle what happens when user enters text on autocomplete
  const onInputChange = (_event, value) => {
    setInputValue(value);
    if (!value) setAutocompleteOpen(false);

    const values = value.toLowerCase();

    if (values) {
      const _filtered = widgets.filter((widget) => {
        if (widget.title.toLowerCase() === values) setAutocompleteOpen(false);
        return widget.title.toLowerCase().startsWith(values);
      });

      setFiltered(_filtered.map((widget) => widget.element));
    } else {
      setFiltered(widgets.map((widget) => widget.element));
    }
  };

  return (
    <div>
      <Button onClick={handleClickOpen()}>Open Widget Settings</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullScreen={fullScreen}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Select Enabled Dashboard Widgets</DialogTitle>
        <DialogContent dividers>
          <Autocomplete
            sx={{ paddingBottom: '15px' }}
            open={autocompleteOpen}
            disableClearable
            onOpen={() => {
              const inputMatches =
                widgets.filter(
                  (widget) =>
                    widget.title.toLowerCase().startsWith(inputValue.toLowerCase()) &&
                    // do not open if query is exact
                    widget.title.toLowerCase() !== inputValue.toLowerCase()
                ).length > 0;
              // only open when in focus and inputValue is not empty
              if (inputValue && inputMatches) {
                setAutocompleteOpen(true);
              }
            }}
            onClose={() => setAutocompleteOpen(false)}
            freeSolo
            inputValue={inputValue}
            options={widgets
              .filter((widget) => widget.title.toLowerCase().startsWith(inputValue.toLowerCase()))
              .map((widget) => widget.title)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Widgets"
                InputProps={{
                  ...params.InputProps,
                  type: 'search',
                }}
              />
            )}
            onInputChange={onInputChange}
          />
          <div className="grid-container">
            {filtered}
            {filtered.length === 0 && 'No widgets matched search'}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default WidgetSettings;
