// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import ArrowUpward from '@mui/icons-material/ArrowUpward';
import ArrowDownward from '@mui/icons-material/ArrowDownward';

function ColumnHeader({ id, display, sortField, reverseSort, onClick }) {
  const handleClick = useCallback(() => onClick(id), [id, onClick]);

  return (
    <TableCell onClick={handleClick} data-testid={display}>
      <Typography textAlign="left" color="tertiary.main" sx={{ fontSize: '1rem' }}>
        <b style={{ userSelect: 'none' }}>{display}</b>
        {sortField === id && !reverseSort && (
          <ArrowUpward color="tertiary" sx={{ fontSize: '1rem' }} />
        )}
        {sortField === id && reverseSort && (
          <ArrowDownward color="tertiary" sx={{ fontSize: '1rem' }} />
        )}
      </Typography>
    </TableCell>
  );
}

export default ColumnHeader;

ColumnHeader.propTypes = {
  id: PropTypes.string,
  display: PropTypes.string,
  sortField: PropTypes.string,
  reverseSort: PropTypes.bool,
  onClick: PropTypes.func,
};
