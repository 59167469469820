// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import ReactAlert from '@mui/material/Alert';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import Close from '@mui/icons-material/Close';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Info from '@mui/icons-material/Info';
import WarningAmber from '@mui/icons-material/WarningAmber';

function Alert({ title, message, setMessage, severity, variant, allowClose, persist }) {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const dialogTitle = {
    error: (
      <>
        <ErrorOutline color="error" />
        &nbsp;&nbsp;{title || 'Error'}
      </>
    ),
    warning: (
      <>
        <WarningAmber color="warning" />
        &nbsp;&nbsp;{title || 'warning'}
      </>
    ),
    info: (
      <>
        <Info color="info" />
        &nbsp;&nbsp;{title || 'info'}
      </>
    ),
  };

  // for snackbars use notistack to allow for multiple at a time
  // this was decided after discussing the viability of using
  // a success alert
  useEffect(() => {
    if (variant === 'snackbar' && !!message) {
      const key = enqueueSnackbar(message, {
        variant: severity,
        autoHideDuration: 3000,
        persist,
        style: { whiteSpace: 'pre-line' },
        onClick: () => closeSnackbar(key),
        onClose: () => setMessage(null),
      });
    }
  }, [closeSnackbar, enqueueSnackbar, message, persist, setMessage, severity, variant]);

  if (variant === 'snackbar') return null;

  if (variant === 'dialog')
    return (
      <Dialog open={!!message} maxWidth="sm" variant="filled" fullWidth>
        <DialogTitle style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
          {dialogTitle[severity]}
        </DialogTitle>
        <DialogContent data-testid="dialogAlert">
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        {allowClose && (
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setMessage(null);
              }}
              autoFocus
            >
              Okay
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={!!message}>
        <ReactAlert
          severity={severity}
          data-testid="alert"
          variant="filled"
          action={
            allowClose && (
              <IconButton
                aria-label="close"
                color={severity === 'info' ? 'secondary' : 'inherit'}
                size="small"
                onClick={() => {
                  setMessage(null);
                }}
                data-testid="close-alert"
              >
                <Close fontSize="inherit" />
              </IconButton>
            )
          }
          sx={{
            mb: 2,
            ...(severity === 'info' && { backgroundColor: 'white' }),
          }}
        >
          {message}
        </ReactAlert>
      </Collapse>
    </Box>
  );
}

Alert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  setMessage: PropTypes.func.isRequired,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']).isRequired,
  variant: PropTypes.oneOf(['alert', 'dialog', 'snackbar']).isRequired,
  allowClose: PropTypes.bool,
  persist: PropTypes.bool,
};

export default Alert;
