import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Loading from './Loading';

function AdminPage() {
  return <Loading />;
}

/**
 * Admin page wrapper; display either the admin page or the subpage outlet
 */
function Admin() {
  const { pathname } = useLocation();

  return pathname.replaceAll('/', '').endsWith('admin') ? <AdminPage /> : <Outlet />;
}
export default Admin;
