// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function useRefThreshold(ref, threshold) {
  const [size, setSize] = useState(null);

  useEffect(() => {
    // track the width of the linked component's ref
    const resizeObserver = new ResizeObserver(([element]) => {
      const { width } = element.contentRect;
      setSize(width >= threshold);
    });
    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref, threshold]);

  return size;
}

export default useRefThreshold;

useRefThreshold.propTypes = { ref: PropTypes.string };
