// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useEffect, useState } from 'react';
import { ConfirmProvider } from 'material-ui-confirm';
import CssBaseline from '@mui/material/CssBaseline';
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import LinearProgress from '@mui/material/LinearProgress';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import Alert from './components/Alert';
import AxiosInstance from './components/AxiosInstance';
import CustomizeTheme from './components/CustomizeTheme';
import Layout from './components/Layout';
import ListGroups from './components/ListGroups';
import ListUsers from './components/ListUsers';
import ProtectedRoute from './components/ProtectedRoute';
import UserProfile from './controllers/UserProfiles';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Pages
// ───────────────────────────────────────────────────────────────────────────
import About from './pages/About';
import Admin from './pages/Admin';
import BrowseDocuments from './pages/BrowseDocuments';
import Home from './pages/Home';
import Page404NotFound from './pages/404';
import Preferences from './pages/Preferences';
import Profile from './pages/Profile';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Contexts
// ───────────────────────────────────────────────────────────────────────────
import { PageProvider } from './contexts/PageContext';
import { UserProvider } from './contexts/UserContext';

// css required by the widget system
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

function App() {
  const [message, setMessage] = useState('');
  const [searchParams] = useSearchParams();
  const { isLoading, isAuthenticated, loginWithRedirect, logout } = useAuth0();

  // if axios receives an error, create a alert with the error message
  AxiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      // if the user gets disabled at any point, alert them and log them out
      // NOTE: error.response is NOT defined for all axios errors!
      if (
        error.response?.status === 403 &&
        error.response?.data?.error === 'User is disabled. Please contact your Administrator.'
      )
        setMessage('Please contact your Administrator');

      return Promise.reject(error);
    }
  );

  useEffect(() => {
    // if the user is not logged in, send an error message
    if (searchParams.get('error')) setMessage('Please contact your Administrator');
  }, [searchParams]);

  const theme = CustomizeTheme();
  const user = UserProfile();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      // Use pathname for redirect - since we're staying within the application
      // add location.search to include any query params added on
      loginWithRedirect({
        appState: { returnTo: window.location.pathname + window.location.search },
      });
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  if (!isAuthenticated) {
    return (
      <div className="App">
        <ThemeProvider theme={theme}>
          <center>
            <LinearProgress />
            <h1>Redirecting to login...</h1>
          </center>
        </ThemeProvider>
      </div>
    );
  }
  return (
    <div className="App">
      {/* During development auth0 will frequently return 304s unless we set referrer to `no-referrer`,
      so only apply this meta tag if in development */}
      {process.env.NODE_ENV && <meta name="referrer" content="no-referrer" />}
      <UserProvider user={user}>
        {/* We put the theme provider above the layout component so all
      customized themes get propagated to it as well */}
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme>
            <SnackbarProvider maxSnack={4}>
              <PageProvider>
                <ConfirmProvider>
                  <Layout>
                    <Routes>
                      <Route index path="/" element={<Home />} />
                      <Route path="/profile" element={<ProtectedRoute page={<Profile />} />} />
                      <Route
                        path="/preferences"
                        element={<ProtectedRoute page={<Preferences />} />}
                      />
                      <Route
                        path="/admin"
                        element={<ProtectedRoute roles={['KT_Administrators']} page={<Admin />} />}
                      >
                        <Route path="groups" element={<ListGroups />} />
                        <Route path="users" element={<ListUsers />} />
                      </Route>
                      <Route path="/browse" element={<ProtectedRoute page={<BrowseDocuments />} />}>
                        <Route path=":id" element={<ProtectedRoute page={<BrowseDocuments />} />} />
                      </Route>
                      <Route path="/about" element={<About />} />
                      <Route path="/404" element={<Page404NotFound />} />
                      <Route path="*" element={<Navigate to="/404" />} />
                    </Routes>
                    <Alert
                      message={message}
                      setMessage={(newMessage) => {
                        setMessage(newMessage);
                        if (isAuthenticated)
                          logout({
                            // returns the user to the home page
                            returnTo: window.location.origin,
                          });
                      }}
                      title="User is disabled"
                      severity="error"
                      variant="dialog"
                    />
                  </Layout>
                </ConfirmProvider>
              </PageProvider>
            </SnackbarProvider>
          </CssBaseline>
        </ThemeProvider>
      </UserProvider>
    </div>
  );
}

export default App;
