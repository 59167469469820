// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import PropTypes from 'prop-types';

const suffixes = ['B', 'KB', 'MB', 'GB', 'TB'];
/**
 * Converts a number of bytes into a simplified human readable format.
 *
 * @param {number} bytes - raw number of bytes
 * @returns - human readable string in either b, Kb, mb, gb, or tb
 */
function HumanFileSize({ bytes }) {
  if (!bytes) return '-';

  const i = bytes === 0 ? 0 : Math.floor(Math.log(bytes) / Math.log(1024));
  return (
    <>
      {(bytes / 1024 ** i).toFixed(2)} {suffixes[i]}
    </>
  );
}

export default HumanFileSize;

HumanFileSize.propTypes = {
  bytes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
