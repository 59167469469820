import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import UhOhRobot from '../assets/404-robot.svg';

function ErrorPage() {
  return (
    <center>
      <Grid
        container
        spacing={12}
        alignItems="center"
        justify="center"
        style={{
          minWidth: '100%',
          minHeight: '70vh',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Grid item xs={6} sx={{ width: '50%' }}>
          <h1>404 Page Not Found</h1>
          <Typography variant="subtitle1">Sorry, an unexpected error has occurred.</Typography>
        </Grid>
        <Grid item xs={6}>
          <img src={UhOhRobot} alt="404" style={{ width: '50%' }} />
        </Grid>
      </Grid>
    </center>
  );
}
export default ErrorPage;
