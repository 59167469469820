// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import Alert from './Alert';
import axios from './AxiosInstance';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Contexts
// ───────────────────────────────────────────────────────────────────────────
import UserContext from '../contexts/UserContext';

const MAGIC_RESET_WORD = 'RESET';

function ResetPreferences({ setMessage }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState('');

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { user } = useContext(UserContext);

  const handleChange = ({ target: { value } }) => {
    setInput(value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setInput('');
    setOpen(false);
  };

  const handleReset = async () => {
    if (input === MAGIC_RESET_WORD) {
      try {
        await axios.delete('/api/user-profile');

        user.metadata.clear();
        // metadata save not needed since we deleted it in the api call
        setMessage('Successfully reset preferences to defaults');
        handleClose();
      } catch (error) {
        setErrorMessage(error.response.data.message);
      }
    }
  };

  return (
    <>
      <Button onClick={handleClickOpen} color="error" data-testid="resetPreferencesDialogBtn">
        Reset Preferences
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullScreen={fullScreen}
        fullWidth
        maxWidth="sm"
        data-testid="resetPreferencesDialog"
      >
        <Alert
          title="Error"
          severity="error"
          variant="alert"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <DialogTitle>Reset Preferences</DialogTitle>
        <DialogContent dividers>
          <Box
            sx={{
              display: 'grid',
              rowGap: 2,
            }}
          >
            <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
              Are you sure you want to reset all your preferences? {'\n\n'}
              If you want to, type in &quot;{MAGIC_RESET_WORD}&quot; and press the button.
            </Typography>

            <TextField
              fullWidth
              id="reset"
              label={MAGIC_RESET_WORD}
              variant="outlined"
              onChange={handleChange}
              data-testid="resetPreferencesTextField"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} data-testid="resetPreferencesDialogCloseBtn">
            Cancel
          </Button>
          <Button
            onClick={handleReset}
            disabled={input !== MAGIC_RESET_WORD}
            color="error"
            variant="contained"
            data-testid="resetPreferencesSubmitBtn"
          >
            Reset
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ResetPreferences;

ResetPreferences.propTypes = {
  setMessage: PropTypes.func.isRequired,
};
