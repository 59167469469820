// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useEffect, useState } from 'react';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import axios from './AxiosInstance';
import Alert from './Alert';

function ListUsers() {
  const [users, setUsers] = useState([]);
  const [checked, setChecked] = useState([0]);
  const [message, setMessage] = useState('');
  const [userDisabledRefreshToggle, setUserDisabledRefreshToggle] = useState(false);

  useEffect(() => {
    axios.get('/api/auth0/users').then(({ data }) => {
      setUsers(data);
    });
  }, [userDisabledRefreshToggle]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleUserToggle = async (user) => {
    try {
      await axios.put('/api/auth0/users', {
        email: user.email,
        blocked: !user.blocked,
      });
      setUserDisabledRefreshToggle(!userDisabledRefreshToggle);
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  return (
    <Paper elevation={0} sx={{ borderRadius: '1.25rem', height: '100%' }}>
      <List sx={{ width: '100%', maxWidth: 360 }}>
        {users.map((user) => {
          const labelId = `checkbox-list-label-${user.user_id}`;

          return (
            <ListItem
              key={user.user_id}
              secondaryAction={
                <IconButton edge="end" onClick={handleUserToggle}>
                  {user.blocked ? <PersonOffIcon /> : <PersonIcon />}
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton role={undefined} onClick={handleToggle(user.user_id)} dense>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(user.user_id) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${user.email}`} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
      <Alert
        title="error"
        message={message}
        severity="error"
        variant="snackbar"
        setMessage={setMessage}
      />
    </Paper>
  );
}

export default ListUsers;
