// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSort } from '@tzmedical/react-hooks';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import ColumnHeader from './common/ColumnHeader';
import ItemRow from './ItemRow';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Contexts
// ───────────────────────────────────────────────────────────────────────────
import FolderDataContext from '../contexts/FolderDataContext';

/**
 * A document browser that displays a list of
 * folders and documents defined in the
 * folders and documents collections;
 *
 * Displays a user's specified preferences for
 * column visibility if defined in their object
 */
function BrowserTable({ isAtLeastMd }) {
  //---------------------------------------------------------------------------
  // Component state management
  //---------------------------------------------------------------------------
  const { folderContents, loading } = useContext(FolderDataContext);
  const sortOptions = useMemo(
    () => ({
      defaultSort: {
        field: 'name',
        reverse: false,
      },
    }),
    []
  );
  const easyFolderContents = useMemo(
    () =>
      folderContents.map((item) => {
        return {
          ...item,
          version: item?.latestApprovedVersion?.version.major,
          fileSize: item?.latestApprovedVersion?.primary.fileSize,
          extension: item?.latestApprovedVersion?.primary.extension,
        };
      }),
    [folderContents]
  );
  const [sortedRows, handleSortSelection, sort] = useSort(easyFolderContents, sortOptions);
  // split sorted rows by type to display folders above documents regardless of sorted state
  const sortedFolderRows = useMemo(
    () => sortedRows.filter(({ type }) => type === 'folder'),
    [sortedRows]
  );
  const sortedDocumentRows = useMemo(
    () => sortedRows.filter(({ type }) => type === 'document'),
    [sortedRows]
  );

  return (
    <Box>
      <TableContainer>
        <Table stickyHeader size="small" data-testid="browse-table">
          <TableHead position="fixed">
            <TableRow>
              <TableCell>
                <Checkbox />
              </TableCell>
              <ColumnHeader
                id="name"
                display="Name"
                sortField={sort.field}
                reverseSort={sort.reverse}
                onClick={handleSortSelection}
              />
              <ColumnHeader
                id="id"
                display="ID"
                sortField={sort.field}
                reverseSort={sort.reverse}
                onClick={handleSortSelection}
              />
              <ColumnHeader
                id="updatedAt"
                display="Last Updated"
                sortField={sort.field}
                reverseSort={sort.reverse}
                onClick={handleSortSelection}
              />
              {isAtLeastMd && (
                <ColumnHeader
                  id="createdBy"
                  display="Creator"
                  sortField={sort.field}
                  reverseSort={sort.reverse}
                  onClick={handleSortSelection}
                />
              )}
              <ColumnHeader
                id="version"
                display="Approved Version"
                sortField={sort.field}
                reverseSort={sort.reverse}
                onClick={handleSortSelection}
              />
              {isAtLeastMd && (
                <ColumnHeader
                  id="fileSize"
                  display="File Size"
                  sortField={sort.field}
                  reverseSort={sort.reverse}
                  onClick={handleSortSelection}
                />
              )}
              <ColumnHeader
                id="extension"
                display="File Type"
                sortField={sort.field}
                reverseSort={sort.reverse}
                onClick={handleSortSelection}
              />
            </TableRow>
            {loading && (
              <TableRow>
                <TableCell colSpan={8}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {sortedFolderRows.map((row) => (
              <ItemRow isAtLeastMd={isAtLeastMd} item={row} key={row.id} />
            ))}
            {sortedDocumentRows.map((row) => (
              <ItemRow isAtLeastMd={isAtLeastMd} item={row} key={row.id} />
            ))}

            {!sortedFolderRows.length && !sortedDocumentRows.length && (
              <TableRow>
                <TableCell colSpan={7}>
                  <center data-testid="folder-is-empty">No folders or documents found</center>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default BrowserTable;

BrowserTable.propTypes = { isAtLeastMd: PropTypes.bool };
