// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useContext } from 'react';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import List from '@mui/material/List';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import BrowseDocumentsIcon from '@mui/icons-material/Folder';
import BrowseDocumentsOutlinedIcon from '@mui/icons-material/FolderOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import UsersIcon from '@mui/icons-material/Group';
import UsersOutlinedIcon from '@mui/icons-material/GroupOutlined';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import NavButton from '../common/NavButton';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Contexts & Hooks
// ───────────────────────────────────────────────────────────────────────────
import PageContext from '../../contexts/PageContext';
import useLogout from '../hooks/useLogout';
import UserContext from '../../contexts/UserContext';

/**
 * A sidebar on the left side that displays available pages to navigate to
 */
function Sidebar() {
  const { PageButtons } = useContext(PageContext);
  const { user } = useContext(UserContext);

  const useLogoutHook = useLogout();

  return (
    <>
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      {/* ─── Browse Menu                                                             */}
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      <div
        style={{
          marginLeft: 2,
          marginTop: 2,
          height: '4rem',
          width: '6.25rem',
        }}
      >
        {PageButtons}
      </div>
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      {/* ─── Side Bar                                                                */}
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      <List
        dense
        sx={{
          '& .MuiListItemButton-root': {
            width: '100%',
            // round borders of selected items
            borderRadius: '1.25rem',
            fontFamily: 'Inter',
            fontWeight: 400,
          },
          '& .MuiListItemText-primary': {
            fontSize: '0.875rem',
          },
          '& .MuiListItemButton-root:hover': {
            // round borders of on hover event
            borderRadius: '1.25rem',
          },
          '& .MuiListItemIcon-root': {
            minWidth: 0,
            // gap between list item icon and list item text
            marginRight: '1rem',
          },
          // list item icon's icon size
          '& .MuiSvgIcon-root': {
            fontSize: 20,
          },
        }}
      >
        {/* ─────────────────────────────────────────────────────────────────────────── */}
        {/* ─── Main Options                                                            */}
        {/* ─────────────────────────────────────────────────────────────────────────── */}
        <NavButton
          Icon={<DashboardOutlinedIcon />}
          SelectedIcon={<DashboardIcon />}
          text="Dashboard"
          to="/"
          data-testid="homePage"
        />
        <NavButton
          Icon={<BrowseDocumentsOutlinedIcon />}
          SelectedIcon={<BrowseDocumentsIcon />}
          text="Browse Documents"
          to="/browse/1"
          data-testid="browseDocumentsPage"
        />

        {/* ─────────────────────────────────────────────────────────────────────────── */}
        {/* ─── Admin Options                                                           */}
        {/* ─────────────────────────────────────────────────────────────────────────── */}
        {user?.isAdmin && (
          <>
            <NavButton
              Icon={<GroupsOutlinedIcon />}
              SelectedIcon={<GroupsIcon />}
              text="Groups"
              to="/admin/groups"
              data-testid="Groups"
            />
            <NavButton
              Icon={<UsersOutlinedIcon />}
              SelectedIcon={<UsersIcon />}
              text="Users"
              to="/admin/users"
              data-testid="Users"
            />
          </>
        )}

        {/* ─────────────────────────────────────────────────────────────────────────── */}
        {/* ─── Sign Out                                                                */}
        {/* ─────────────────────────────────────────────────────────────────────────── */}
        <NavButton
          Icon={<LogoutIcon />}
          SelectedIcon={<LogoutIcon />}
          text="Sign Out"
          onClick={useLogoutHook}
          data-testid="signOut"
        />
      </List>
    </>
  );
}

export default Sidebar;
