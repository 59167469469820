import { createTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo } from 'react';

const customizeReactTheme = () => {
  // check the os settings to see if we need to use dark mode
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // return a usable theme object each time the prefers-color-scheme value changes
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useMemo(() => {
    const dark = {
      palette: {
        mode: 'dark',
        primary: {
          main: 'rgb(22,150,231)',
        },
        secondary: {
          main: '#245797',
        },
        background: {
          default: '#1f2022',
        },
        action: {
          active: '#8ab4f8',
          hover: '#8ab4f8',
          focus: '#8ab4f8',
          selected: '#c58af9',
        },
        profile: {
          backgroundColor: '#272727',
        },
      },
      overrides: {
        '& .Mui-selected': {
          backgroundColor: '#0d3648',
        },
      },
      searchBar: {
        background: '#21252d',
        icon: '#f5f5f5',
      },
      sidebar: {
        button: {
          background: 'black',
          text: 'white',
        },
      },
    };

    const light = {
      palette: {
        mode: 'light',
        primary: {
          main: 'rgb(22,150,231)',
        },
        secondary: {
          main: '#245797',
        },
        background: {
          default: '#f7f9fc',
        },
        profile: {
          backgroundColor: '#fff',
        },
      },
      overrides: {
        '& .Mui-selected': {
          backgroundColor: '#c2e7ff',
        },
      },
      searchBar: {
        background: '#EDF2FC',
        icon: 'inherit',
      },
      sidebar: {
        button: {
          background: 'white',
          text: 'black',
        },
      },
    };

    const additionalProps = {
      overrides: {
        MuiAppBar: {
          colorInherit: {
            backgroundColor: '#F5F5F5',
            color: '#494949',
          },
        },
      },
      props: {
        MuiAppBar: {
          color: 'inherit',
        },
      },
      shape: {
        borderRadius: 6,
      },
    };

    return createTheme(
      prefersDarkMode ? { ...dark, ...additionalProps } : { ...light, ...additionalProps }
    );
  }, [prefersDarkMode]);
};

export default customizeReactTheme;
