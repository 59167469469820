// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import DocumentIcon from '@mui/icons-material/InsertDriveFile';
import FolderIcon from '@mui/icons-material/Folder';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import HumanFileSize from './common/HumanFileSize';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Contexts
// ───────────────────────────────────────────────────────────────────────────
import FolderDataContext from '../contexts/FolderDataContext';

function ItemRow({ isAtLeastMd, item }) {
  const [hover, setHover] = useState(false);
  const { selectedItems, setSelectedItems } = useContext(FolderDataContext);
  const navigate = useNavigate();

  const isItemSelected = useMemo(
    () => selectedItems.some(({ id }) => id === item?.id),
    [item, selectedItems]
  );
  const handleRowNavigation = useCallback(
    () => item?.type === 'folder' && navigate(`/browse/${item.id}`),
    [item, navigate]
  );
  const handleSelect = useCallback(
    () =>
      isItemSelected
        ? setSelectedItems((prev) => prev.filter(({ id }) => id !== item.id))
        : setSelectedItems((prev) => [...prev, item]),
    [isItemSelected, item, setSelectedItems]
  );
  const handleEnter = useCallback(() => setHover(true), []);
  const handleLeave = useCallback(() => setHover(false), []);
  const Icon = useMemo(() => (item?.type === 'folder' ? FolderIcon : DocumentIcon), [item?.type]);

  return (
    <TableRow
      onClick={handleSelect}
      onDoubleClick={handleRowNavigation}
      // TODO: IAD-509 mobile does not work, you need to long press/tap
      selected={isItemSelected}
      style={{
        filter: item.archived && !isItemSelected ? 'opacity(0.5)' : 'inherit',
      }}
    >
      <TableCell
        data-testid={`cell-${item.id}-${item.type}`}
        sx={{ display: 'flex' }}
        onMouseEnter={handleEnter}
        onMouseLeave={handleLeave}
      >
        {hover || isItemSelected ? (
          <Checkbox size="small" checked={isItemSelected} />
        ) : (
          <Icon sx={{ color: 'text.secondary' }} />
        )}
      </TableCell>
      <TableCell data-testid={`cell-${item.id}-name`}>{item.name}</TableCell>
      <TableCell data-testid={`cell-${item.id}-id`}>{item.id}</TableCell>
      <TableCell data-testid={`cell-${item.id}-updated-at`}>
        {new Date(item.updatedAt).toLocaleDateString('en-us')}
      </TableCell>
      {isAtLeastMd && (
        <TableCell data-testid={`cell-${item.id}-created-by`}>{item.createdBy}</TableCell>
      )}
      <TableCell data-testid={`cell-${item.id}-approved-version`}>
        {item?.latestApprovedVersion
          ? `v${item?.latestApprovedVersion.version.major}.${item?.latestApprovedVersion.version.minor}`
          : '-'}
      </TableCell>
      {isAtLeastMd && (
        <TableCell data-testid={`cell-${item.id}-file-size`}>
          {item?.latestApprovedVersion && (
            <HumanFileSize bytes={item?.latestApprovedVersion.primary.fileSize} />
          )}
        </TableCell>
      )}
      <TableCell data-testid={`cell-${item.id}-file-type`}>
        {item?.latestApprovedVersion ? `.${item.latestApprovedVersion.primary.extension}` : '-'}
      </TableCell>
    </TableRow>
  );
}

export default ItemRow;
ItemRow.propTypes = {
  isAtLeastMd: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  item: PropTypes.object,
};
