// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { createContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { capitalize } from '@mui/material';

const PageContext = createContext();

export function PageProvider({ children }) {
  const [title, setTitle] = useState(null);
  const [PageButtons, setPageButtons] = useState(null);
  const { pathname } = useLocation();
  const memoizedValue = useMemo(
    () => ({
      title: title || capitalize(pathname.split('/').at(-1).replaceAll('-', ' ')) || 'Summit',
      setTitle,
      PageButtons,
      setPageButtons,
    }),
    [title, pathname, PageButtons]
  );

  return <PageContext.Provider value={memoizedValue}>{children}</PageContext.Provider>;
}

export default PageContext;

PageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
