/**
 * Used for validating folder/document names
 * Only allow A-Z, a-z, 0-9, _, -, ., comma, (), and space for names
 * */
const validateNameInput = (value, name = 'It') => {
  // Used for validating folder/document names
  const regex = /^[A-Za-z0-9_\-.,() ]+$/;

  if (value.startsWith('-') || value.startsWith('.') || value.startsWith('_')) {
    return `${name} cannot start with hyphen, underscore, or period`;
  }

  if (value.endsWith('-') || value.endsWith('.') || value.endsWith('_')) {
    return `${name} cannot end with hyphen, underscore, or period`;
  }

  if (value.length > 255) {
    return `${name} cannot be longer than 255 characters`;
  }

  if (name.trim().length === 0 || !name.match(regex)) {
    return `${name} can only contain upper/lowercase characters, numbers, hyphens, underscores, commas, parentheses, spaces, or periods`;
  }

  return true;
};

export default validateNameInput;
