/* eslint-disable react/forbid-prop-types */
// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

function FormStringInput({
  control,
  name,
  label,
  id,
  defaultValue,
  required = true,
  disabled = false,
  options,
  rules,
  onChange,
  textFieldOptions,
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister
      render={({ field, fieldState: { error } }) => (
        <TextField
          required={required}
          label={label}
          id={id}
          select={!!options}
          variant="standard"
          disabled={disabled}
          fullWidth
          {...field}
          {...textFieldOptions}
          onChange={(e) => {
            // This updates the form field value displayed to the user
            // so it's very important to immediately execute this, otherwise
            // the user won't see input, or will see delayed input as they type
            field.onChange(e);

            if (onChange) {
              onChange(e);
            }
          }}
          error={!!error}
          helperText={error ? error.message : null}
          data-testid={id}
        >
          {!!options &&
            options.map((option) => (
              <MenuItem key={option.id} value={option.id} id={`${id}-${option.id}`}>
                {option.name}
              </MenuItem>
            ))}
        </TextField>
      )}
      rules={rules}
    />
  );
}

FormStringInput.propTypes = {
  /**
   * The [control](https://react-hook-form.com/api/useform/control)
   * object provided by invoking `useForm`.
   * Optional when using `FormProvider`.
   *
   * @example
   * ```js
   * import { useForm } from 'react-hook-form';
   * const { control, handleSubmit, getValues } = useForm();
   * ```
   * pass in control
   */
  control: PropTypes.object.isRequired,

  /**
   * Unique name of your input field
   */
  name: PropTypes.string.isRequired,

  /**
   * The label content
   */
  label: PropTypes.string.isRequired,

  /**
   * The id of your input field
   */
  id: PropTypes.string,

  /**
   * **Important:** cannot apply undefined to `defaultValue`
   * * You need to either set `defaultValue` at the field-level
   *   or `useForm`'s `defaultValue`s.  Undefined is not a valid value.
   * * If your form will invoke `reset` with default values,
   *   you will need to provide `useForm` with `defaultValues`
   */
  defaultValue: PropTypes.string,

  /**
   * If `true`, the label is displayed as required
   * and the input element is required.
   */
  required: PropTypes.bool,

  /**
   * If `true` the component is disabled
   */
  disabled: PropTypes.bool,

  /**
   * If `true` enables a select menu
   * Each item must be an object that contains
   * `id` and `name` properties
   */
  options: PropTypes.array,

  /**
   * Validation rules in the same format for `register`,
   * which includes:
   * required, min, man, minLength, maxLength, pattern, validate
   *
   * @example
   * ```jsx
   * rules={{ required: true }}
   * ```
   */
  rules: PropTypes.object,

  /**
   * Custom user `onChange` function for `TextField`'s `onChange`
   */
  onChange: PropTypes.func,

  /**
   * Additional props to pass to `TextField`
   * For more information, see [TextField API](https://mui.com/material-ui/api/text-field/)
   */
  textFieldOptions: PropTypes.object,
};

export default FormStringInput;
