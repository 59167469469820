import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';

import UserContext from '../contexts/UserContext';

function Profile() {
  const { user } = useContext(UserContext);

  if (!user) return null;

  return (
    <Container maxWidth="lg">
      <Card
        // Add a  bigger box shadow than normal
        elevation={2}
        sx={{
          // Extra space above the card, so our Avatar can "stick up"
          marginTop: '120px',
          // boxShadow: '2',
          overflow: 'visible',
        }}
      >
        <CardContent align="center" sx={{ paddingTop: '0' }}>
          <Avatar
            src={user.picture}
            alt={user.name}
            sx={{
              // Make sure there's a background for transparent Avatar images
              backgroundColor: 'profile.backgroundColor',
              // Set the size to match the google profile image size
              width: '96px',
              height: '96px',
              // Move it up half the image height (set right above this line)
              transform: 'translateY(-48px)',
              boxShadow: '2',
            }}
          />
          <div
            // Set the top margin to the same value we shifted the Avatar image, so there's no weird gap
            style={{ marginTop: '-48px' }}
          >
            <h2>{user.name}</h2>
            <p>{user.email}</p>
            <Link to="/">Home</Link>
          </div>
        </CardContent>
      </Card>
    </Container>
  );
}

export default Profile;
