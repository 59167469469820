// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';

function Search() {
  const theme = useTheme();

  return (
    <Paper
      component="form"
      elevation={0}
      sx={{
        bgcolor: theme.searchBar.background,
        borderRadius: '6.25rem',
        display: 'flex',
        justifyContent: 'space-between',
        paddingLeft: '2%',
        paddingRight: '2%',
        width: '100%',
      }}
    >
      <IconButton>
        <SearchIcon />
      </IconButton>
      <InputBase sx={{ ml: 1, width: '95%', color: theme.searchBar.icon }} placeholder="Search" />
      <IconButton>
        <TuneIcon />
      </IconButton>
    </Paper>
  );
}

export default Search;
