// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import CloseIcon from '@mui/icons-material/Close';
import FolderIcon from '@mui/icons-material/Folder';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Contexts
// ───────────────────────────────────────────────────────────────────────────
import ArchiveIcon from '@mui/icons-material/ArchiveOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/EditOutlined';
import MoveIcon from '@mui/icons-material/DriveFileMoveOutlined';
import RestoreIcon from '@mui/icons-material/UnarchiveOutlined';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import ArchiveAction from './browse-document-actions/Archive';
import EditAction from './browse-document-actions/EditFolder';
import FolderDataContext from '../contexts/FolderDataContext';
import DeleteAction from './browse-document-actions/Delete';
import MoveAction from './browse-document-actions/Move';

function CurrentFolderMetadata({ handleClose }) {
  const [currentTab, setCurrentTab] = useState(0);
  // IAD-232 will take care of document logic
  const { currentFolder } = useContext(FolderDataContext);

  const updatedAtDate = useMemo(
    () => new Date(currentFolder.updatedAt).toDateString(),
    [currentFolder.updatedAt]
  );
  const createdAtDate = useMemo(
    () => new Date(currentFolder.createdAt).toDateString(),
    [currentFolder.createdAt]
  );

  const handleChange = useCallback((event, newTab) => setCurrentTab(newTab), [setCurrentTab]);
  const typographyProps = useMemo(() => ({ fontSize: '0.875rem', fontWeight: 400 }), []);

  return (
    <Box sx={{ paddingTop: '4.5%', paddingLeft: '3%' }} data-testid="current-folder-metadata">
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      {/* ─── Header Actions                                                          */}
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      <Grid container spacing={0} justifyContent="center" alignItems="center">
        <Grid item xs={2}>
          <FolderIcon sx={{ color: 'text.secondary', fontSize: '1.75rem' }} />
        </Grid>
        <Grid item xs={7} data-testid="current-folder-name">
          {currentFolder.archived && (
            <Chip
              color="default"
              data-testid="archived-item-chip"
              label="Archived"
              size="small"
              icon={<ArchiveIcon />}
            />
          )}
          &nbsp;{currentFolder.name}
        </Grid>
        <Grid item xs={3} sx={{ textAlign: 'right' }}>
          <IconButton onClick={handleClose} data-testid="current-folder-metadata-toggle">
            <CloseIcon sx={{ fontSize: '1.75rem' }} />
          </IconButton>
        </Grid>
      </Grid>
      <Stack justifyContent="center" direction="row" spacing={-1}>
        <MoveAction folderData={currentFolder}>
          <MoveIcon sx={{ color: 'text.secondary' }} data-testid="static-move-action" />
        </MoveAction>
        <DeleteAction folderData={currentFolder}>
          <DeleteIcon sx={{ color: 'text.secondary' }} data-testid="static-delete-action" />
        </DeleteAction>
        <ArchiveAction folderData={currentFolder}>
          {currentFolder.archived ? (
            <RestoreIcon sx={{ color: 'text.secondary' }} data-testid="static-restore-action" />
          ) : (
            <ArchiveIcon sx={{ color: 'text.secondary' }} data-testid="static-archive-action" />
          )}
        </ArchiveAction>
        <EditAction folderData={currentFolder}>
          <EditIcon sx={{ color: 'text.secondary' }} data-testid="static-edit-action" />
        </EditAction>
      </Stack>
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      {/* ─── Tabs                                                                  */}
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      <Tabs value={currentTab} onChange={handleChange} variant="fullWidth">
        <Tab label="Details" index={0} sx={{ textTransform: 'none' }} />
        <Tab label="Activity" index={1} sx={{ textTransform: 'none' }} />
      </Tabs>
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      {/* ─── Details Tab                                                           */}
      {/* ─────────────────────────────────────────────────────────────────────────── */}
      <Box hidden={currentTab !== 0}>
        <center style={{ marginTop: 10 }}>
          <FolderIcon sx={{ color: 'text.secondary', fontSize: '5.5rem' }} />
        </center>
        <Divider sx={{ mb: 2 }} />
        <Typography sx={{ fontWeight: 445 }}>Folder Details</Typography>
        <List>
          <ListItemText
            data-testid="id"
            primary="ID"
            primaryTypographyProps={typographyProps}
            secondaryTypographyProps={typographyProps}
            secondary={currentFolder.id}
          />
          <ListItemText
            data-testid="creator"
            primary="Creator"
            primaryTypographyProps={typographyProps}
            secondaryTypographyProps={typographyProps}
            secondary={currentFolder.createdBy}
          />
          <ListItemText
            data-testid="modified"
            primary="Modified"
            primaryTypographyProps={typographyProps}
            secondary={updatedAtDate}
            secondaryTypographyProps={typographyProps}
          />
          <ListItemText
            data-testid="created"
            primary="Created"
            primaryTypographyProps={typographyProps}
            secondary={createdAtDate}
            secondaryTypographyProps={typographyProps}
          />
          {currentFolder.description && (
            <ListItemText
              data-testid="current-folder-description"
              primary="Description"
              primaryTypographyProps={typographyProps}
              secondary={currentFolder.description}
              secondaryTypographyProps={{
                ...typographyProps,
                whiteSpace: 'pre-wrap',
                hyphens: 'auto',
              }}
            />
          )}
        </List>
      </Box>
      <Box hidden={currentTab !== 1}>work in progress...</Box>
    </Box>
  );
}

export default CurrentFolderMetadata;

CurrentFolderMetadata.propTypes = { handleClose: PropTypes.func };
