// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import MuiListItemButton from '@mui/material/ListItemButton';

function ActionsListItemButton({ onClick, disabled, children }) {
  return (
    <MuiListItemButton
      onClick={onClick}
      disabled={disabled}
      sx={{
        borderRadius: '50%',
        width: '3em',
        height: '3em',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </MuiListItemButton>
  );
}

export default ActionsListItemButton;

ActionsListItemButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};
