// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

const SWIPEABLE_AREA_WIDTH = 56;

function ResponsiveDrawer({ threshold, children, ...drawerProps }) {
  if (!threshold) {
    return (
      <SwipeableDrawer
        swipeAreaWidth={SWIPEABLE_AREA_WIDTH}
        disableSwipeToOpen={false}
        {...drawerProps}
      >
        {children}
      </SwipeableDrawer>
    );
  }

  return children;
}

export default ResponsiveDrawer;

ResponsiveDrawer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  drawerProps: PropTypes.object,
  threshold: PropTypes.bool,
  children: PropTypes.node,
};
