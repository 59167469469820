/* eslint-disable no-console */
// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import axios from '../components/AxiosInstance';

/**
 * Object which manages metadata for the user
 */
class Metadata {
  /**
   * Create a new metadata instance with properties from metadata object
   *
   * @param {Object} metadata - initial metadata
   */
  constructor(metadata) {
    // merge this and metadata
    Object.assign(this, metadata);
  }

  /**
   * Update the metadata with new property(s)
   * will be shallow merged with existing metadata
   *
   * @param {Object} metadata - metadata to add or change
   */
  update(metadata) {
    Object.assign(this, metadata);
  }

  /**
   * Clear the metadata completely
   */
  clear() {
    Object.getOwnPropertyNames(this).forEach((prop) => delete this[prop]);
  }

  /**
   * Delete one metadata property
   *
   * @param {String} name
   */
  deleteOne(name) {
    delete this[name];
  }

  /**
   * Save the metadata to the database
   */
  save() {
    const { ...metadata } = this;

    (async () => {
      await axios.put('/api/user-profile', metadata).catch((error) => {
        console.error(error);
      });
    })();
  }

  /**
   * Deletes the entire user collection from the server
   */
  deleteFromDb() {
    this.clear();

    (async () => {
      await axios.delete(`/api/user-profile`).catch((error) => {
        console.error(error);
      });
    })();
  }
}

/**
 * Get the user metadata from db and combine it with auth0 user object
 * Returns user object; null if user is not authenticated.
 *
 * @return {Object} The user object
 */
function UserProfile() {
  const { user, isAuthenticated } = useAuth0();
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (isAuthenticated) {
        // get user, if it doesn't exist create it
        const definedUser = (await axios.get('/api/user-profile')).data;

        user.groups = await (await axios.get('/api/groups/mine')).data;
        user.isAdmin = user.groups.some((group) => group.name === 'KT_Administrators');
        setUserProfile(definedUser);
      }
    };

    fetchUserData().catch(console.error);
  }, [isAuthenticated, user]);

  // nothing to do unless we're authenticated
  if (userProfile === null) {
    return null;
  }

  user.metadata = new Metadata(userProfile.metadata);

  return user;
}

export default UserProfile;
