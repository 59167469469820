// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useEffect, useState } from 'react';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import axios from '../components/AxiosInstance';

function About() {
  const [version, setVersion] = useState('');

  useEffect(() => {
    axios.get('/api/version').then(({ data: { version: appVersion } }) => {
      setVersion(appVersion);
    });
  }, []);

  return (
    <Card>
      <CardContent>
        <h1>Summit | K2</h1>
        Software Version: {version}
      </CardContent>
    </Card>
  );
}
export default About;
