// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useCallback, useEffect, useRef, useState } from 'react';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Collapse from '@mui/material/Collapse';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import ToolTip from '@mui/material/Tooltip';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import axios from './AxiosInstance';
import ListItemComponent from './ListItemComponent';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Hooks
// ───────────────────────────────────────────────────────────────────────────
import useInterval from './useInterval';

/**
 * List item component wrapper that displays all groups,
 * and supports all crud operations for them
 */
function ListGroups() {
  const [groups, setGroups] = useState([]);
  const [newGroupName, setNewGroupName] = useState('');
  const [fabOpen, setFabOpen] = useState(false);
  const groupNameInput = useRef('');

  const handleToggleFab = useCallback(() => setFabOpen((prev) => !prev), []);
  const fetchGroups = useCallback(async () => {
    const { data } = await axios.get('/api/groups');
    setGroups(data);
  }, [setGroups]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  useInterval(fetchGroups, 30000);

  const handleAdd = async () => {
    const { data } = await axios.post('/api/groups', {
      name: newGroupName,
    });

    setGroups([...groups, data]);
    setNewGroupName('');
    groupNameInput.current.value = '';
  };

  const handleChange = useCallback((e) => setNewGroupName(e.target.value), []);

  return (
    <Paper elevation={0} sx={{ borderRadius: '1.25rem', height: '100%' }}>
      <List sx={{ maxWidth: '100%' }}>
        <ToolTip title="Add Group">
          <Fab aria-label="add" size="small" onClick={handleToggleFab}>
            {fabOpen ? <RemoveIcon /> : <AddIcon />}
          </Fab>
        </ToolTip>
        <Collapse in={fabOpen}>
          <ListItem>
            <Input placeholder="New Group" inputRef={groupNameInput} onChange={handleChange} />
            <IconButton onClick={handleAdd}>
              <CheckIcon />
            </IconButton>
          </ListItem>
        </Collapse>
        <ListItemComponent groups={groups} />
      </List>
    </Paper>
  );
}

export default ListGroups;
