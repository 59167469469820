// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ToolTip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Hooks
// ───────────────────────────────────────────────────────────────────────────
import useLogout from '../hooks/useLogout';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Contexts
// ───────────────────────────────────────────────────────────────────────────
import UserContext from '../../contexts/UserContext';

/**
 * Profile menu component that displays,
 * on click, shows a dropdown menu of specified options
 */
function ProfileMenu() {
  const useLogoutHook = useLogout();
  const { user } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => setAnchorEl(null);
  const handleOpen = (event) => setAnchorEl(event.currentTarget);

  return (
    <>
      <ToolTip title="Profile" sx={{ ml: 'auto' }}>
        <IconButton onClick={handleOpen} data-testid="avatar">
          <Avatar alt="Avatar" src={user?.picture} />
        </IconButton>
      </ToolTip>
      <Menu
        keepMounted
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem key="profile" component={Link} to="/profile" data-testid="profile">
          <Typography textAlign="center">Profile</Typography>
        </MenuItem>
        <MenuItem key="preferences" component={Link} to="/preferences" data-testid="preferences">
          <Typography textAlign="center">Preferences</Typography>
        </MenuItem>
        <MenuItem key="about" component={Link} to="/about" data-testid="about">
          <Typography textAlign="center">About</Typography>
        </MenuItem>
        <MenuItem key="logout" onClick={useLogoutHook} data-testid="menu-logout">
          Logout
        </MenuItem>
      </Menu>
    </>
  );
}

export default ProfileMenu;
