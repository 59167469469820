// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Imports
// ───────────────────────────────────────────────────────────────────────────
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

function NavButton({ Icon, SelectedIcon, text, to, onClick, 'data-testid': dataTestId }) {
  // use location to highlight when our link matches the current page
  const location = useLocation();
  const isSelected = useMemo(() => location.pathname === to, [location.pathname, to]);

  if (to) {
    return (
      <ListItemButton
        component={Link}
        to={to}
        selected={isSelected}
        onClick={onClick}
        data-testid={dataTestId}
      >
        <ListItemIcon sx={{ color: 'text.secondary' }}>
          {isSelected ? SelectedIcon : Icon}
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    );
  }

  return (
    <ListItemButton component={Link} onClick={onClick} data-testid={dataTestId}>
      <ListItemIcon sx={{ color: 'text.secondary' }}>
        {isSelected ? SelectedIcon : Icon}
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItemButton>
  );
}

export default NavButton;

NavButton.propTypes = {
  Icon: PropTypes.node,
  SelectedIcon: PropTypes.node,
  text: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
  'data-testid': PropTypes.string,
};
