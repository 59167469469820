// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Components
// ───────────────────────────────────────────────────────────────────────────
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Input from '@mui/material/Input';
import ListItem from '@mui/material/ListItem';
import ToolTip from '@mui/material/Tooltip';

// ───────────────────────────────────────────────────────────────────────────
// ─── Material Ui Icons
// ───────────────────────────────────────────────────────────────────────────
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

// ───────────────────────────────────────────────────────────────────────────
// ─── Summit Components
// ───────────────────────────────────────────────────────────────────────────
import axios from './AxiosInstance';

/**
 * Lists all the items in the provided list
 *
 * Groups param's expected shape:
 * [{
 *  name: string,
 *  _id: string
 * }, ...]
 *
 * @param {Array.<Object>} groups - an array of groups to display
 * @returns
 */
function ListItemComponent({ groups }) {
  const [currentGroups, setCurrentGroups] = useState(groups);
  const [toggleUpdateGroup, setToggleUpdateGroup] = useState('');
  const [updateGroupName, setUpdateGroupName] = useState('');

  useEffect(() => setCurrentGroups(groups), [groups]);

  const handleEdit = async ({ _id }) => {
    const { status } = await axios.put(`/api/groups/${_id}`, {
      name: updateGroupName,
    });

    if (status === 200) {
      const { data } = await axios.get('/api/groups');
      setCurrentGroups(data);
      setToggleUpdateGroup('');
    }
  };

  const handleDelete = async ({ _id }) => {
    const { status } = await axios.delete(`/api/groups/${_id}`);

    if (status === 200) {
      setCurrentGroups(currentGroups.filter((g) => g._id !== _id));
    }
  };

  return currentGroups?.map((group, index) => (
    <div key={group._id}>
      <ListItem
        secondaryAction={
          <>
            {toggleUpdateGroup === index ? (
              <>
                <ToolTip title="Submit">
                  <IconButton
                    aria-label="submit"
                    onClick={() => {
                      handleEdit(group);
                    }}
                  >
                    <CheckIcon />
                  </IconButton>
                </ToolTip>
                <ToolTip title="Cancel">
                  <IconButton
                    aria-label="cancel"
                    onClick={() => {
                      setToggleUpdateGroup('');
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </ToolTip>
              </>
            ) : (
              <ToolTip title="Edit">
                <IconButton aria-label="edit" onClick={() => setToggleUpdateGroup(index)}>
                  <EditIcon />
                </IconButton>
              </ToolTip>
            )}
            <ToolTip title="Delete">
              <IconButton aria-label="delete" onClick={() => handleDelete(group)}>
                <DeleteIcon />
              </IconButton>
            </ToolTip>
          </>
        }
      >
        {toggleUpdateGroup === index ? (
          <Input
            autoFocus
            defaultValue={group.name}
            onChange={(e) => {
              setUpdateGroupName(e.target.value);
            }}
          />
        ) : (
          <Box component="div" sx={{ textOverflow: 'ellipsis' }}>
            {group.name}
          </Box>
        )}
      </ListItem>
      <Divider key={`di-${group._id}`} />
    </div>
  ));
}

export default ListItemComponent;

ListItemComponent.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};
