// ───────────────────────────────────────────────────────────────────────────
// ─── React Imports
// ───────────────────────────────────────────────────────────────────────────
import React, { useEffect } from 'react';

const useInterval = (callback, delay, immediately) => {
  // ───────────────────────────────────────────────────────────────────────────
  // Save the callback for later use
  // ───────────────────────────────────────────────────────────────────────────
  const savedCallback = React.useRef();
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // ───────────────────────────────────────────────────────────────────────────
  // This useEffect handles creation and (importantly) cleanup of the interval
  // ───────────────────────────────────────────────────────────────────────────
  useEffect(() => {
    const tick = () => savedCallback.current();
    if (delay !== null) {
      // Schedule the periodic callback
      const id = setInterval(tick, delay);
      // Run the callback once now, if needed
      if (immediately) {
        tick();
      }
      // Cleanup the periodic callback
      return () => {
        clearInterval(id);
      };
    }
    return () => {};
  }, [savedCallback, delay, immediately]);
};

export default useInterval;
